import './NewCustomer.css';
import {useEffect, useState} from 'react';
import Logo from '../refs/CornerstoneColor.jpg';
import { useSearchParams } from "react-router-dom";
import { ImCheckboxUnchecked, ImCheckboxChecked  } from "react-icons/im";
import { FaStar, FaCheck  } from "react-icons/fa";

import newCustomerDataService from '../Services/NewCustomerService';

// CTRL+K+0 = Collapse ALL
// CTRL+K+} = expand current block
// CTRL+K+{ =collapse current block


// SERVER INTERACTION - - - - - - -

// SEND OBJECT TO BACK END
const submitCustomerRequest = async (customerObject,setFormSubmissionValid) => {
    newCustomerDataService.submitCustomerRecord(customerObject).then(response => {
        if(response.status > 199 && response.status < 300) setFormSubmissionValid(true);
        else setFormSubmissionValid(false);
    })
};

const sendW9 = async (w9, filename, setW9Id, setW9Error) => {
    newCustomerDataService.submitW9(w9,filename)
    .then(response =>{
        setW9Id(response.data.id);
    }).catch( e=> {
        setW9Error(e.toString());
    });
};

const sendInternationalWire = async (intWire, filename, setIntWireId, setIntWireError) => {
    newCustomerDataService.submitIntWire(intWire,filename)
    .then(response =>{
        setIntWireId(response.data.id);
    }).catch( e=> {
        setIntWireError(e.toString());
    });
};



// HELPER FUNCTIONS - - - - - - - - - - -
const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = mm + '.' + dd + '.' + yyyy;
    return today;
};


const SignatureInput = ({title,setTo,complete=false ,width=0}) => {
    const [displayWidth, setDisplayWidth ] = useState('auto');

    const inputCssProps = {
        '--inputWidth': `${displayWidth}`
    };

    useEffect(() => {
        if(width > 0) setDisplayWidth(`${width}px`)
        else setDisplayWidth('auto')
    },[])

    return(
        <>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='true'/>
        <link href="https://fonts.googleapis.com/css2?family=Whisper&display=swap" rel="stylesheet"/>

        <div className='External-Form-Input' style={inputCssProps}>
            {   !complete &&
                <FaStar className='External-Form-Input-Required' />
            }
            {   complete &&
                <FaCheck className='External-Form-Input-Complete' />
            }
            
            <div className='External-Form-Input-Title'>
                {title}
            </div>
            <input type="text" className='External-Form-Signature-Box' onChange={(e) => setTo(e.target.value)} />
        </div>
        </>
    )
}

const TextInput = ({title,setTo, complete=false ,width=0,charCount=0,required=false,active=true, under=false, initializedValue=null}) => {
    const [displayWidth, setDisplayWidth ] = useState('auto');

    const inputCssProps = {
        '--inputWidth': `${displayWidth}`
    };

    useEffect(() => {
        if(width>0){
            setDisplayWidth(`${width}px`)
        }
        else{
            if(charCount > 0){
                setDisplayWidth(`${charCount*18}px`);
            }
            else{
                setDisplayWidth('auto')
            }
        }
    },[])

    if(!under){
        return(
            <div className='External-Form-Input' style={inputCssProps}>
                {   active && required && !complete &&
                <FaStar className='External-Form-Input-Required' />
                }
                {   active && !required && !complete &&
                <FaStar className='External-Form-Input-Not-Required' />
                }
                {   active && complete &&
                <FaCheck className='External-Form-Input-Complete' />
                }
                <div className='External-Form-Input-Title'>
                    {title}
                </div>
                {   charCount > 0 &&
                    <input type="text" placeholder={initializedValue} inputMode='numeric' maxLength={charCount} className='External-Form-Input-Contrained' onChange={(e) => setTo(e.target.value)}/>
                }
                {   charCount < 1 &&
                    <input type="text" placeholder={initializedValue} className='External-Form-Input-Box' onChange={(e) => setTo(e.target.value)} />
                }
                
            </div>
        )
    }
    else{
        return(
            <div className='External-Form-Input-Under' style={inputCssProps}>
                {   active && required && !complete &&
                <FaStar className='External-Form-Input-Required' />
                }
                {   active && !required && !complete &&
                <FaStar className='External-Form-Input-Not-Required' />
                }
                {   active && complete &&
                <FaCheck className='External-Form-Input-Complete' />
                }

                {   charCount > 0 &&
                    <input type="text" inputMode='numeric' placeholder={initializedValue} maxLength={charCount} className='External-Form-Input-Contrained' onChange={(e) => setTo(e.target.value)}/>
                }
                {   charCount < 1 &&
                    <input type="text" className='External-Form-Input-Box' placeholder={initializedValue} onChange={(e) => setTo(e.target.value)} />
                }

                <div className='External-Form-Input-Title-Under'>
                    {title}
                </div>
                
            </div>
        )
    }

};

const DropDownSelection = ({title, options, setTo, complete=false, width, active, required, initializedValue}) => {
    const inputCssProps = {
        '--inputWidth': `${width}`
    };

    const selectOption = (value) => {
         setTo(value);
    };

    return(
        <div className='External-Form-Input' style={inputCssProps}>
        {   active && required && !complete &&
        <FaStar className='External-Form-Input-Required' />
        }
        {   active && required && complete &&
        <FaCheck className='External-Form-Input-Complete' />
        }
        <div className='External-Form-Input-Title'>
            {title}
        </div>
        {   !initializedValue &&
        <select className='External-Form-Dropdown'onChange={(e) => selectOption(e.target.value)}>
            <option value={false}> </option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        }
        {   initializedValue &&
        <select className='External-Form-Dropdown' onChange={(e) => selectOption(e.target.value)}>
            <option value={true}>{initializedValue}</option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        }

        
    </div>
    )
};

const AccountSelector = ({title, selected, setTo}) => {
    return(
        <div className='External-Vendor-Type-Selection-Group'>
            {   !selected &&
                <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => setTo(true)}/>
            }
            {   selected &&
                <ImCheckboxChecked className='External-Vendor-Type-Selection' onClick={() => setTo(false)}/>
            }
            <div className='External-Vendor-Type-Selection-Text'>
                {title}
            </div>
        </div>
    )
};

const ClassificationSelector = ({value,classificationList,addClassification,removeClassification}) => {
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if(classificationList){
            if(classificationList.includes(value)){
                setSelected(true);
            }
            else setSelected(false);
        }
        else setSelected(false);

    },[classificationList]);

    return(
        <div className='External-Vendor-Type-Selection-Group'>
            {   !selected &&
                <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => addClassification(value)}/>
            }
            {   selected &&
                <ImCheckboxChecked className='External-Vendor-Type-Selection' onClick={() => removeClassification(value)}/>
            }
            <div className='External-Vendor-Type-Selection-Text'>
                {value}
            </div>
        </div>
    )
};

const checkEmail = (email) => {
    let valid = true;
    try{
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if(re.test(email)){
            // Valid Email
        }
        else{
            valid = false;
        }
    }
    catch{
        valid = false;
    }

    return valid;
};
/*
const sendInternationalWire = async (intWire, filename, setIntWireId, setIntWireError) => {
    newVendorDataService.submitIntWire(intWire,filename)
    .then(response =>{
        setIntWireId(response.data.id);
    }).catch( e=> {
        setIntWireError(e.toString());
    });
};
*/


// DISPLAY SECTIONS - - - - - - - - - -
const FormHeader = ({formEmail}) => {
    return(
        <div className='External-Form-Header'>
            <img src={Logo} className='External-Form-Logo' alt='Cornerstone Logo'/>

            <div className='External-Form-Contact'>
                <div className='External-Form-Contact-Content'>
                    2511 Midpark Road, Montgomery, AL 36109
                </div>
                <div className='External-Form-Contact-Content'>
                    (334) 286-4333
                </div>
            </div>
            <div className='External-Form-Email-Support'>
                {formEmail}
            </div>
            <div className='External-Vendor-Title'>
                    New CUSTOMER FORM
            </div>
        </div>
    )
};

// Customer INFORMATION SECTION
const CustomerInformation = ({CustomerInformationActive,setFedEinSSn,fedEinSSn,fedEinSSnComplete,setDunsNo,dunsNo,dunsNoComplete,setWebsite,

    website,websiteComplete,setLegalName,legalName,legalNameComplete,setTradeNameDBA,tradeNameDBA,tradeNameDBAComplete,setStreet,street,setStreet2,
    street2,streetComplete,setCity,city,cityComplete,setState,state,stateComplete,setZip,zip,zipComplete,setCountry,country,setShippingStreet,
    shippingStreet,shippingStreetComplete,setShippingStreet2,shippingStreet2,setShippingCity,shippingCity,shippingCityComplete,setShippingState,
    shippingState,shippingStateComplete,setShippingZip,shippingZip,shippingZipComplete,setShippingCountry,shippingCountry,primaryContactName,
    setPrimaryContactName,primaryContactNameComplete,setPrimaryContactNumber,primaryContactNumber,primaryContactNumberComplete,setPrimaryContactEmail,primaryContactEmail,
    primaryContactEmailComplete,taxExemptOptions,setTaxExemptCert,taxExemptCert,taxExemptCertComplete,setContactNameAP,contactNameAP,contactNameAPComplete,setPhoneAP,
    phoneAP,phoneAPComplete,setContactEmailAP,contactEmailAP,contactEmailAPComplete,paymentOptions,setPaymentMethod,paymentMethod,paymentMethodComplete,
    businessTypeOptions,setBusinessTypeSelection,businessTypeSelection,businessTypeSelectionComplete,setStateOfInc,stateOfInc,stateOfIncComplete,}) => {

    const [addressComplete, setAddressComplete] = useState(false);

    useEffect(() => {
        if(stateComplete && cityComplete && stateComplete && zipComplete) setAddressComplete(true);
        else setAddressComplete(false);
    },[streetComplete,cityComplete,stateComplete,zipComplete]);

    return(
        <>
        {   
            <div className='External-Customer-Information'>
                {   CustomerInformationActive &&
                    <>
                    <div className='External-Customer-Title External-Customer-Bold'>
                        Please Fill in Your Information Below.
                    </div>
                    </>

                }

               { <div className='External-Company-Section'>
                          Company Information
                </div>
                }
                <div className='External-Form-Spacer'>

                </div>

                <TextInput title={'Federal EIN/SSN:'} setTo={setFedEinSSn} complete={fedEinSSnComplete} charCount={9} required={true} active={CustomerInformationActive} initializedValue={fedEinSSn}/>
                <TextInput title={'Duns No.:'} setTo={setDunsNo} complete={dunsNoComplete} charCount={9} required={true} active={CustomerInformationActive} initializedValue={dunsNo}/>
                <TextInput title={'Website:'} setTo={setWebsite} complete={websiteComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={website}/>
                <TextInput title={'Legal Name:'} setTo={setLegalName} complete={legalNameComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={legalName}/>
                <TextInput title={'Trade Name/DBA:'} setTo={setTradeNameDBA} complete={tradeNameDBAComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={tradeNameDBA}/>

                <div className='External-Form-Spacer'>

                </div>
                <div className='External-Form-Input-Title'>
                Billing Address:
                </div>
                <TextInput title={'Street:'} setTo={setStreet} complete={streetComplete} width={220} required={true} active={CustomerInformationActive} under={true} initializedValue={street}/>
                <TextInput title={'Street2:'} setTo={setStreet2} width={120} required={false} active={CustomerInformationActive} under={true} initializedValue={street2}/>
                <TextInput title={'City:'} setTo={setCity} complete={cityComplete} width={140} required={true} active={CustomerInformationActive} under={true} initializedValue={city}/>
                <TextInput title={'State:'} setTo={setState} complete={stateComplete} charCount={2} width={40} required={true} active={CustomerInformationActive} under={true} initializedValue={state}/>
                <TextInput title={'Zip:'} setTo={setZip} complete={zipComplete} charCount={5} required={true} active={CustomerInformationActive} under={true} initializedValue={zip}/>
                <TextInput title={'Country:'} setTo={setCountry} charCount={2} required={false} active={CustomerInformationActive} under={true} initializedValue={country}/>

                <div className='External-Form-Spacer'>
                </div>
                <div className='External-Form-Input-Title2'>
                Shipping Address:
                </div>
                <TextInput title={'Street:'} setTo={setShippingStreet} complete={shippingStreetComplete} width={220} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingStreet}/>
                <TextInput title={'Street2:'} setTo={setShippingStreet2} width={120} required={false} active={CustomerInformationActive} under={true} initializedValue={shippingStreet2}/>
                <TextInput title={'City:'} setTo={setShippingCity} complete={shippingCityComplete} width={140} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingCity}/>
                <TextInput title={'State:'} setTo={setShippingState} complete={shippingStateComplete} charCount={2} width={40} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingState}/>
                <TextInput title={'Zip:'} setTo={setShippingZip} complete={shippingZipComplete} charCount={5} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingZip}/>
                <TextInput title={'Country:'} setTo={setShippingCountry} charCount={2} required={false} active={CustomerInformationActive} under={true} initializedValue={shippingCountry}/>

                <div className='External-Form-Spacer'>
                </div>
                
                <TextInput title={'Contact Name (Primary):'} setTo={setPrimaryContactName} complete={primaryContactNameComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={primaryContactName}/>
                <TextInput title={'Phone:'} setTo={setPrimaryContactNumber} complete={primaryContactNumberComplete} charCount={10} required={true} active={CustomerInformationActive} initializedValue={primaryContactNumber}/>
                <TextInput title={'Email Address:'} setTo={setPrimaryContactEmail} complete={primaryContactEmailComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={primaryContactEmail}/>
                <DropDownSelection title={'Tax Exempt:'} setTo={setTaxExemptCert} complete={taxExemptCertComplete} options={taxExemptOptions} width={200} required={true} active={CustomerInformationActive} initializedValue={taxExemptCert}/>
                <TextInput title={'Contact Name (Account Payable):'} setTo={setContactNameAP} complete={contactNameAPComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={contactNameAP}/>
                <TextInput title={'Phone:'} setTo={setPhoneAP} complete={phoneAPComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={phoneAP}/>
                <TextInput title={'Email Address:'} setTo={setContactEmailAP} complete={contactEmailAPComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={contactEmailAP}/>
                <DropDownSelection title={'Payment Method:'} setTo={setPaymentMethod} complete={paymentMethodComplete} options={paymentOptions} width={200} required={true} active={CustomerInformationActive} initializedValue={paymentMethod}/>
                <DropDownSelection title={'Business Type:'} setTo={setBusinessTypeSelection} complete={businessTypeSelectionComplete} options={businessTypeOptions} width={200} required={true} active={CustomerInformationActive} initializedValue={businessTypeSelection}/>
                <TextInput title={'State Of Incorporation:'} setTo={setStateOfInc} complete={stateOfIncComplete} width={100} required={true} active={CustomerInformationActive} initializedValue={stateOfInc}/>
                

                
            </div>
        }
        </>
    )
};

/*
const TaxCertificateSection = ({taxCertActive,setTaxCert}) => {
    return (
        <div className='External-Customer-Information'>
            {taxCertActive &&
                <>
                <div className='External-Tax-Form-Title'>
                    Tax Certificate
                </div>
                </>
            }
            {
                !taxCertActive &&
                <div className='External-Customer-Thick-Spacer' />
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
           Place Your Certificate of Tax Exemption Below:
            </div>
            <input type='file' accept="application/pdf" onChange={(e) => setTaxCert(e.target.files)} className='External-Form-File-Upload'/>
        </div>
    )
};
*/

const CreditCardInformation = ({creditCardActive,setNameOnCard,nameOnCard,nameOnCardComplete,setCardNumber,cardNumber,cardNumberComplete,setExpDate,expDate,
    expDateComplete,setCvv,cvv,cvvComplete,setCardZip,cardZip,cardZipComplete}) => {
    return(
        <div className='External-Customer-Card-Information'>
            <div className='External-Customer-Card-Title'>
               Credit Card Information
            </div>

            <div className='External-Form-Spacer'>
            </div>

            <div className='External-Card-Information-Group'>
            <TextInput title={'Name On Card:'} setTo={setNameOnCard} complete={nameOnCardComplete} width={340} required={true} active={creditCardActive} initializedValue={nameOnCard}/>
            <TextInput title={'Card Number:'} setTo={setCardNumber} complete={cardNumberComplete} width={340} required={true} active={creditCardActive} initializedValue={cardNumber}/>
            
            <div className='External-Form-Spacer'>
            </div>

            <TextInput title={'Exp Date:'} setTo={setExpDate} complete={expDateComplete} width={140} required={true} active={creditCardActive} initializedValue={expDate}/>
            <TextInput title={'Security Code:'} setTo={setCvv} complete={cvvComplete} charCount={2} width={40} required={true} active={creditCardActive} initializedValue={cvv}/>
            <TextInput title={'Zip:'} setTo={setCardZip} complete={cardZipComplete} charCount={5} required={true} active={creditCardActive} initializedValue={cardZip}/>
            </div>

        
        </div>
    )
};

const AchInformation = ({achActive,setBankName,bankName,bankNameComplete,setBankContactFirstName,bankContactFirstName,setBankContactLastName,bankContactLastName,bankContactNameComplete,
    setBankStreet,bankStreet,setBankStreet2,bankStreet2,bankStreetComplete,setBankCity,bankCity,bankCityComplete,setBankState,bankState,bankStateComplete,
    setBankZip,bankZip,bankZipComplete,setBankCountry,bankCountry,bankCountryComplete,setBankTelephone,bankTelephone,bankTelephoneComplete,
    setBankContactEmail,bankContactEmail,bankContactEmailComplete,setBankAccountName,bankAccountName,bankAccountNameComplete,
    setBankAccountNumber,bankAccountNumber,bankAccountNumberComplete,setBankRoutingNumber,bankRoutingNumber,bankRoutingNumberComplete,
    setBankSwiftCode,bankSwiftCode,bankSwiftCodeComplete,setBankAccountSelection,bankAccountSelection,bankAccountSelectionComplete}) => {
    
    const [ checkingSelected, setCheckingSelected ] = useState(true);
    const [ savingsSelected, setSavingsSelected ] = useState(false);
    const [ addressComplete, setAddressComplete] = useState(false);

    useEffect(() => {
        if(bankStateComplete && bankCityComplete && bankStateComplete && bankZipComplete) setAddressComplete(true);
        else setAddressComplete(false);
    },[bankStateComplete,bankCityComplete,bankStateComplete,bankZipComplete]);


    const toggleSelection = (value) => {
        if(value === 'Checking'){
            setSavingsSelected(false);
            setCheckingSelected(true);
            setBankAccountSelection('Checking');
        }
        else{
            setSavingsSelected(true);
            setCheckingSelected(false);
            setBankAccountSelection('Savings');
        }
    };

    useEffect(() => {
        if(bankAccountSelection === 'Checking'){
            setSavingsSelected(false);
            setCheckingSelected(true);
        }
        else{
            setSavingsSelected(true);
            setCheckingSelected(false);
        }
    },[bankAccountSelection]);

    return(
        <div className='External-Customer-Information'>
            <div className='External-Customer-ACH-Title'>
                Banking Information
            </div>

            

            <div className='External-Form-Spacer'>
            </div>
                
            <TextInput title={'Bank Name:'} setTo={setBankName} complete={bankNameComplete} width={280} required={true} active={achActive} initializedValue={bankName}/>
            <TextInput title={'Name on Account:'} setTo={setBankAccountName} complete={bankAccountNameComplete} width={300} required={true} active={achActive} initializedValue={bankAccountName}/>

            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            Bank Address:
            </div>
            <TextInput title={'Street:'} setTo={setBankStreet}complete={bankStreetComplete} width={220} required={true} active={achActive} under={true} initializedValue={bankStreet}/>
            <TextInput title={'Street2:'} setTo={setBankStreet2} width={120} required={false} active={achActive} under={true} initializedValue={bankStreet2}/>
            <TextInput title={'City:'} setTo={setBankCity} complete={bankCityComplete} width={140} required={true} active={achActive} under={true} initializedValue={bankCity}/>
            <TextInput title={'State:'} setTo={setBankState} complete={bankStateComplete} charCount={2} width={40} required={true} active={achActive} under={true} initializedValue={bankState}/>
            <TextInput title={'Zip:'} setTo={setBankZip} complete={bankZipComplete} charCount={5} required={true} active={achActive} under={true} initializedValue={bankZip}/>
            <TextInput title={'Country:'} setTo={setBankCountry} complete={bankCountryComplete} charCount={2} required={false} active={achActive} under={true} initializedValue={bankCountry}/>
            <div className='External-Form-Spacer'>
            </div>

            <TextInput title={'Bank Telephone:'} setTo={setBankTelephone} complete={bankTelephoneComplete} charCount={10} required={true} active={achActive} initializedValue={bankTelephone}/>
            <TextInput title={'Bank Contact First Name:'} setTo={setBankContactFirstName} complete={bankContactNameComplete} width={150} required={true} active={achActive} initializedValue={bankContactFirstName}/>
            <TextInput title={'Bank Contact Last Name:'} setTo={setBankContactLastName} complete={bankContactNameComplete} width={150} required={true} active={achActive} initializedValue={bankContactLastName}/>
            <TextInput title={'Bank Contact Email:'} setTo={setBankContactEmail} complete={bankContactEmailComplete} width={300} required={true} active={achActive} initializedValue={bankContactEmail}/>
            <TextInput title={'Account Number:'} setTo={setBankAccountNumber} complete={bankAccountNumberComplete} charCount={17} required={true} active={achActive} initializedValue={bankAccountNumber}/>
            <TextInput title={'Routing Number:'} setTo={setBankRoutingNumber} complete={bankRoutingNumberComplete} charCount={9} required={true} active={achActive} initializedValue={bankRoutingNumber}/>
            <TextInput title={'Swift Code:'} setTo={setBankSwiftCode} complete={bankSwiftCodeComplete} charCount={11}  active={achActive} initializedValue={bankSwiftCode}/>

            <AccountSelector title={'Checking'} selected={checkingSelected} setTo={() => toggleSelection('Checking')}/>
            <AccountSelector title={'Savings'} selected={savingsSelected} setTo={() => toggleSelection('Savings')}/>

            </div>
    )
};    


//Required Files 
const RequiredFilesSection = ({paymentMethod,setW9,w9Active,intWireActive,voidedCheckActive,taxCertActive,setIntWire,setFinishedW9,setVoidedCheckFile,setTaxCert}) => {
    console.log(paymentMethod)
    return(
        <div className='External-Required-Files'>
            <div className='External-Customer-Required-Files-Title'>
                Required Files 
            </div>

            <div className='External-Form-Spacer'>
            </div>

            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                PDF version of completed W9:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setW9(e.target.files)} className='External-Form-File-Upload'/>
            </div>

            {   paymentMethod === 'ACH' &&
            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                    PDF version of Voided Check or Bank Letter:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setVoidedCheckFile(e.target.files)} className='External-Form-File-Upload'/>
            </div>
            }

            {   paymentMethod === 'International Wire' &&
            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                  PDF version of International Wire Instructions:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setIntWire(e.target.files)} className='External-Form-File-Upload'/>
            </div>
            }

            {   taxCertActive &&
            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                    PDF version of Certificate of Tax Exemption:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setTaxCert(e.target.files)} className='External-Form-File-Upload'/>
            </div>
            }

        </div>
    )

};

/*
// INTERNATIONAL WIRE SECTION
const InternationWireInformation = ({intWireActive, setIntWire}) => {
    return(
        <div className='External-Customer-Information'>
            {   intWireActive &&
                <>
                    <div className='External-Customer-Type-Title-External-Customer-Bold'>
                        International Wire Instructions
                    </div>
                </>
            }
            {
                !intWireActive &&
                <div className='External-Customer-Thick-Spacer'/>
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            PDF version of International Wire Instructions :
            </div>
            
        </div>
    )
};


// W9 SECTION
const W9Section = ({w9Active, setFinishedW9}) => {
    return(
        <div className='External-Customer-Information'>
            {   w9Active &&
                <>
                    <div className='External-Customer-Type-Title-External-Customer-Bold'>
                        W9
                    </div>
                   
                </>
            }
            {
                !w9Active &&
                <div className='External-Vendor-Thick-Spacer'>
                </div>
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            PDF version of completed W9:
            </div>
            <input type='file' accept="application/pdf" onChange={(e) => setFinishedW9(e.target.files)} className='External-Form-File-Upload'/>
        </div>
    )
};
*/

//Owner/s or Authorized Officers
const OwnersOrOfficersInformation = ({OwnersOrOfficersInformationActive,setName1,name1,name1Complete,setTitle,title,titleComplete,setPhone,phone,phoneComplete,setHomeAddress,homeAddress,
homeAddressComplete,setEmailAddress,emailAddress,emailAddressComplete,setSecondaryOwner,secondaryOwner,setName2,name2,name2Complete,setTitle2,title2,title2Complete,setPhone2,
phone2,phone2Complete,setHomeAddress2,homeAddress2,homeAddress2Complete,setEmailAddress2,emailAddress2,emailAddress2Complete
}) => {
        return(
        <>
        {   
            <div className='External-Owners-Or-Officers-Information'>
                
                <div className='External-Owners-Section'>
                          Owner/s or Authorized Officers
                </div>

                <div className='External-Form-Spacer'>

                </div>
                <div className='External-Customer-Contact-Group'> 
                <TextInput title={'Name:'} setTo={setName1} complete={name1Complete} width={340} required={true} active={OwnersOrOfficersInformationActive} initializedValue={name1}/>
                <TextInput title={'Title:'} setTo={setTitle} complete={titleComplete} width={340} required={true} active={OwnersOrOfficersInformationActive} initializedValue={title}/>
                <TextInput title={'Phone:'} setTo={setPhone} complete={phoneComplete} charCount={10} required={true} active={OwnersOrOfficersInformationActive} initializedValue={phone}/>
                <TextInput title={'Home Address:'} setTo={setHomeAddress} complete={homeAddressComplete} width={400} required={true} active={OwnersOrOfficersInformationActive} initializedValue={homeAddress}/>
                <TextInput title={'Email Address:'} setTo={setEmailAddress} complete={emailAddressComplete} width={300} required={true} active={OwnersOrOfficersInformationActive} initializedValue={emailAddress}/>
                </div>

                {   !secondaryOwner && 
                    <button onClick={() => setSecondaryOwner(true)} className='External-Customer-Contact-Button Button-Add'>Add Secondary Contact</button>
                }
                {   secondaryOwner && 
                   <button onClick={() => setSecondaryOwner(false)} className='External-Customer-Contact-Button Button-Remove'>Remove Secondary Contact</button>
                }
                { secondaryOwner && 
                <div className='External-Customer-Contact-Group'>
                    <TextInput title={'Name:'} setTo={setName2} complete={name2Complete} width={340} required={true} active={OwnersOrOfficersInformationActive} initializedValue={name2}/>
                    <TextInput title={'Title:'} setTo={setTitle2} complete={title2Complete} width={340} required={true} active={OwnersOrOfficersInformationActive} initializedValue={title2}/>
                    <TextInput title={'Phone:'} setTo={setPhone2} complete={phone2Complete} charCount={10} required={true} active={OwnersOrOfficersInformationActive} initializedValue={phone2}/>
                    <TextInput title={'Home Address:'} setTo={setHomeAddress2} complete={homeAddress2Complete} width={400} required={true} active={OwnersOrOfficersInformationActive} initializedValue={homeAddress2}/>
                    <TextInput title={'Email Address:'} setTo={setEmailAddress2} complete={emailAddress2Complete} width={300} required={true} active={OwnersOrOfficersInformationActive} initializedValue={emailAddress2}/>
                    
                </div>
                }
            </div>  

            

            
        }
        </>
        )
};

/*
//BANK REFERENCE
const BankReferenceInformation = ({BankReferenceInformationActive,setBankNameReference,bankNameReference,bankNameReferenceComplete,setBankContactName,bankContactName,bankContactNameReferenceComplete,
    setBankAddress,bankAddress,bankAddressComplete,setBankPhone,bankPhone,bankPhoneComplete,setBankEmail,bankEmail,bankEmailComplete,setNameOnAccount,
    nameOnAccount,nameOnAccountComplete
 }) => {
        return(
        <>
        {
            <div className='External-Bank-Reference-Section'>
                
                <div className='External-Bank-Reference-Title'>
                    Bank Reference
                </div>

                <div className='External-Form-Spacer'>

                </div>

                <TextInput title={'Bank Name:'} setTo={setBankNameReference} complete={bankNameReferenceComplete} width={340} required={true} active={BankReferenceInformationActive} initializedValue={bankNameReference}/>
                <TextInput title={'Bank Contact Name:'} setTo={setBankContactName} complete={bankContactNameReferenceComplete} width={340} required={true} active={BankReferenceInformationActive} initializedValue={bankContactName}/>
                <TextInput title={'Bank Address:'} setTo={setBankAddress} complete={bankAddressComplete} width={700} required={true} active={BankReferenceInformationActive} initializedValue={bankAddress}/>
                <TextInput title={'Bank Phone No:'} setTo={setBankPhone} complete={bankPhoneComplete} charCount={10} required={true} active={BankReferenceInformationActive} initializedValue={bankPhone}/>
                <TextInput title={'Bank Contact Email:'} setTo={setBankEmail} complete={bankEmailComplete} width={340} required={true} active={BankReferenceInformationActive} initializedValue={bankEmail}/>
                <TextInput title={'Name on Account:'} setTo={setNameOnAccount} complete={nameOnAccountComplete} width={700} required={true} active={BankReferenceInformationActive} initializedValue={nameOnAccount}/>


            </div> 
        }
        </>
    )
 };
*/

const TradeReferencesInformation = ({TradeReferencesInformationActive,setCompanyName,companyName,companyNameComplete,setContactName,contactName,
    contactNameComplete,setTradeEmail,tradeEmail,tradeEmailComplete,setTradePhone,tradePhone,tradePhoneComplete,setSecondaryReference,secondaryReference,setCompanyName2,companyName2,companyName2Complete,
    setContactName2,contactName2,contactName2Complete,setTradeEmail2,tradeEmail2,tradeEmail2Complete,setTradePhone2,tradePhone2,tradePhone2Complete,
    setThirdReference,thirdReference,setCompanyName3,companyName3,companyName3Complete,setContactName3,contactName3,contactName3Complete,setTradeEmail3,tradeEmail3,tradeEmail3Complete,setTradePhone3,
    tradePhone3,tradePhone3Complete
}) => {
    return(
        <>
        {
            <div className='External-Trade-Reference-Information'>
                
                <div className='External-Trade-Reference-Title'>
                    Trade References
                </div>

                <div className='External-Form-Spacer'>

                </div>
                <div className='External-Trade-Group'>  
                <TextInput title={'Company Name:'} setTo={setCompanyName} complete={companyNameComplete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={companyName}/>
                <TextInput title={'Contact Name:'} setTo={setContactName} complete={contactNameComplete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={contactName}/>
                <TextInput title={'Email Address:'} setTo={setTradeEmail} complete={tradeEmailComplete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={tradeEmail}/>
                <TextInput title={'Phone No:'} setTo={setTradePhone} complete={tradePhoneComplete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={tradePhone}/>
                </div>

                <div className='External-Form-Spacer'>

                </div>

                {   !secondaryReference && 
                    <button onClick={() => setSecondaryReference(true)} className='External-Customer-Trade-Reference-Button Button-Add'>Add Second Reference</button>
                }
                {   secondaryReference && 
                   <button onClick={() => setSecondaryReference(false)} className='External-Customer-Trade-Reference-Button Button-Remove'>Remove Second Reference</button>
                }

                { secondaryReference && 
                <div className='External-Trade-Group'> 
                    <TextInput title={'Company Name:'} setTo={setCompanyName2} complete={companyName2Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={companyName2}/>
                    <TextInput title={'Contact Name:'} setTo={setContactName2} complete={contactName2Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={contactName2}/>
                    <TextInput title={'Email Address:'} setTo={setTradeEmail2} complete={tradeEmail2Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={tradeEmail2}/>
                    <TextInput title={'Phone No:'} setTo={setTradePhone2} complete={tradePhone2Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={tradePhone2}/>
                </div>
                }

                <div className='External-Form-Spacer'>

                </div>

                {   secondaryReference && !thirdReference && 
                    <button onClick={() => setThirdReference(true)} className='External-Customer-Contact-Button Button-Add'>Add Third Reference</button>
                }
                {   thirdReference && secondaryReference &&
                   <button onClick={() => setThirdReference(false)} className='External-Customer-Contact-Button Button-Remove'>Remove Third Reference</button>
                }

                { thirdReference && secondaryReference &&
                <div className='External-Trade-Group'> 
                <TextInput title={'Company Name:'} setTo={setCompanyName3} complete={companyName3Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={companyName3}/>
                <TextInput title={'Contact Name:'} setTo={setContactName3} complete={contactName3Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={contactName3}/>
                <TextInput title={'Email Address:'} setTo={setTradeEmail3} complete={tradeEmail3Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={tradeEmail3}/>
                <TextInput title={'Phone No:'} setTo={setTradePhone3} complete={tradePhone3Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={tradePhone3}/>
                </div>
                }
            </div>
        }
        </>
    )
};


const ConditionsAndAuthorizations = ({ConditionsAndAuthorizationsActive,setNameSign,nameSign,nameSignComplete,setTitleSign,titleSign,titleSignComplete,
    setSignature,signatureComplete,setDate,date,dateComplete,setSecondarySignature,secondarySignature,setNameSign2,nameSign2,nameSign2Complete,setTitleSign2,titleSign2,titleSign2Complete,
    setSignature2,signature2Complete,setDate2,date2,date2Complete}) => {
        const conditionString = 'We certify that we are authorized to provide this information, and that the information on this form is correct to the best of our knowledge. We affirm financial responsibility, and it is understood that payment terms are net thirty (30) days and that any balances not paid within these terms shall be subject to a late charge. We agree to pay a monthly finance charge of 1.50% per month or 18% annually on all balances more than thirty (30) days past due. We acknowledge that any invoice more than sixty (60) days past due shall be sent to a collection agency. We agree to pay all costs of collection and litigation on this account, and by signing below, we acknowledge and agree that any legal proceeding or dispute related to this form or the relationship between the parties named herein will be governed by and interpreted according to the laws of the State of Alabama. We agree that all decisions with respect to the extension or continuation of business shall be in the sole discretion of the Seller.'
        return(
           <>
           {
            <div className='External-Conditions-Section'>
                
                <div className='External-Conditions-Title'>
                    Conditions and Authorizations
                </div>

                <div className='External-Conditions-Text'>
                    {conditionString}
                </div>
            
            <div className='External-Conditions-Informmation'>
            <TextInput title={`Name:`} setTo={setNameSign} complete={nameSignComplete} width={220} required={true} initializedValue={nameSign}/>
            <TextInput title={`Title:`} setTo={setTitleSign} complete={titleSignComplete} width={170} required={true} initializedValue={titleSign}/>
            <SignatureInput title={`Signature:`} setTo={setSignature} complete={signatureComplete} width={220}/>
            <TextInput title={`Date:`} setTo={setDate} complete={dateComplete} width={50} required={true} initializedValue={date}/>

                {   !secondarySignature && 
                    <button onClick={() => setSecondarySignature(true)} className='External-Customer-Trade-Reference-Button Button-Add'>Add Second Signaturee</button>
                }
                {   secondarySignature && 
                   <button onClick={() => setSecondarySignature(false)} className='External-Customer-Trade-Reference-Button Button-Remove'>Remove Second Signature</button>
                }

            { secondarySignature && 
            <div className='External-Conditions-Informmation'>
                <TextInput title={`Name:`} setTo={setNameSign2} complete={nameSignComplete} width={220} required={false} initializedValue={nameSign2}/>
                <TextInput title={`Title:`} setTo={setTitleSign2} complete={titleSignComplete} width={170} required={false} initializedValue={titleSign2}/>
                <SignatureInput title={'Signature:'} setTo={setSignature2} complete={signature2Complete} width={220}/>
                <TextInput title={`Date:`} setTo={setDate2} complete={date2Complete} width={50} required={false} initializedValue={date2}/>
            </div>
            }
            </div>

               
                
            </div>
           }
           </>
        )
    };
    


// PRIMARY FUNCTION - - - - - - - - -
const NewCustomer = () => {
    // DEFAULTS
    const formEmail = 'For questions or support, please email: ACH@cornerstoneinc.com';
    const businessTypeOptions = ['Corporation', 'Partnership' , 'Sole Proprietorship', 'Limmited Liability Company (LLC)', 'Government'];
    const paymentOptions = ['Credit Card (5K or Less ONLY)', 'ACH', 'International Wire'];
    const taxExemptOptions = ['Yes (Provide Certificate)', 'No'];
   // const w9Options = ['Yes', 'No'];

     // CUSTOMER INFORMATION STATE
     const [ customerInformationActive, setcustomerInformationActive ] = useState(false);
     const [ customerInformationComplete, setcustomerInformationComplete ] = useState(false);
     const [ fedEinSSn, setFedEinSSn ] = useState('');
     const [ fedEinSSnComplete, setFedEinSSnComplete ] = useState(false);
     const [ website, setWebsite ] = useState('');
     const [ websiteComplete, setwebsiteComplete ] = useState(false);
     const [ dunsNo, setDunsNo ] = useState('');
     const [ dunsNoComplete, setdunsNoComplete ] = useState(false);
     const [ legalName, setLegalName ] = useState('');
     const [ legalNameComplete, setLegalNameComplete ] = useState(false);
     const [ tradeNameDBA, setTradeNameDBA ] = useState('');
     const [ tradeNameDBAComplete, setTradeNameDBAComplete ] = useState(false);
     const [ street, setStreet ] = useState('');
     const [ street2, setStreet2 ] = useState('');
     const [ streetComplete, setStreetComplete ] = useState(false);
     const [ city, setCity ] = useState('');
     const [ cityComplete, setCityComplete ] = useState(false);
     const [ state, setState ] = useState('');
     const [ stateComplete, setStateComplete ] = useState(false);
     const [ zip, setZip ] = useState('');
     const [ zipComplete, setZipComplete ] = useState(false);
     const [ country, setCountry ] = useState('');
     const [ shippingStreet, setShippingStreet ] = useState('');
     const [ shippingStreet2, setShippingStreet2 ] = useState('');
     const [ shippingStreetComplete, setShippingStreetComplete ] = useState(false);
     const [ shippingCity, setShippingCity ] = useState('');
     const [ shippingCityComplete, setShippingCityComplete ] = useState(false);
     const [ shippingState, setShippingState ] = useState('');
     const [ shippingStateComplete, setShippingStateComplete ] = useState(false);
     const [ shippingZip, setShippingZip ] = useState('');
     const [ shippingZipComplete, setShippingZipComplete ] = useState(false);
     const [ shippingCountry, setShippingCountry ] = useState('');
     const [ primaryContactName, setPrimaryContactName ] = useState('');
     const [ primaryContactNameComplete, setPrimaryContactNameComplete ] = useState(false);
     const [ primaryContactNumber, setPrimaryContactNumber ] = useState('');
     const [ primaryContactNumberComplete, setPrimaryContactNumberComplete ] = useState(false);
     const [ primaryContactEmail, setPrimaryContactEmail ] = useState('');
     const [ primaryContactEmailComplete, setPrimaryContactEmailComplete ] = useState(false);
     const [ taxExemptCert, setTaxExemptCert ] = useState('');
     const [ taxExemptCertComplete, setTaxExemptCertComplete ] = useState(false);
     const [ contactNameAP, setContactNameAP ] = useState('');
     const [ contactNameAPComplete, setContactNameAPComplete ] = useState(false);
     const [ phoneAP, setPhoneAP ] = useState('');
     const [ phoneAPComplete, setPhoneAPComplete ] = useState(false);
     const [ contactEmailAP, setContactEmailAP ] = useState('');
     const [ contactEmailAPComplete, setContactEmailAPComplete ] = useState(false);
     const [ paymentMethod, setPaymentMethod ] = useState('');
     const [ paymentMethodComplete, setPaymentMethodComplete ] = useState(false);
     const [ businessTypeSelection , setBusinessTypeSelection ] = useState('');
     const [ businessTypeSelectionComplete, setBusinessTypeSelectionComplete ] = useState(false);
     const [ stateOfInc, setStateOfInc ] = useState('');
     const [ stateOfIncComplete, setStateOfIncComplete ] = useState(false);

    //Required Files State
    const [ taxCertActive, setTaxCertActive ] = useState(false);
    const [ taxCert, setTaxCert ] = useState();
    const [ taxCertId, setTaxCertId ] = useState();

    const [ w9Active, setW9Active ] = useState(true);
    const [ finishedW9, setFinishedW9 ] = useState();
    const [ w9, setW9 ] = useState('');
    const [ w9Complete, setW9Complete ] = useState(false);
    const [ w9Id, setW9Id ] = useState();
    const [ w9Error, setW9Error ] = useState('');
    const [ w9Required, setW9Required ] = useState(false);
    const [ intWireActive, setIntWireActive ] = useState(false);
    const [ intWire, setIntWire ] = useState();
    const [ intWireId, setIntWireId ] = useState();
    const [ intWireError, setIntWireError ] = useState('');
    const [ voidedCheckActive, setVoidedCheckActive ] = useState(true);
    const [ voidedCheckFile, setVoidedCheckFile ] = useState();
    const [ voidedCheckFileId, setVoidedCheckFileId ] = useState();
    const [ filesRequired, setFilesRequired ] =useState(false);

    //Tax Exempt State
    //const [ taxCertShown, setTaxCertShown ] = useState(false);
   // const [ taxCertActive, setTaxCertActive ] = useState(false);
   // const [ taxCert, setTaxCert ] = useState();

   //Credit Card State
    const [ creditCardActive, setCreditCardActive ] = useState(false);
    const [ nameOnCard, setNameOnCard ] = useState('');
    const [ nameOnCardComplete, setNameOnCardComplete ] = useState(false);
    const [ cardNumber, setCardNumber ] = useState('');
    const [ cardNumberComplete, setCardNumberComplete ] = useState(false);
    const [ expDate, setExpDate ] = useState('');
    const [ expDateComplete, setExpDateComplete ] = useState(false);
    const [ cvv, setCvv ] = useState('');
    const [ cvvComplete, setCvvComplete ] = useState(false);
    const [ cardZip, setCardZip ] = useState('');
    const [ cardZipComplete, setCardZipComplete ] = useState(false);

    //ACH State
    const [ achShown, setAchShown ] = useState(false);
    const [ achActive, setAchActive ] = useState(false);
    const [ achComplete, setAchComplete ] = useState(false);
    const [ bankName, setBankName ] = useState('');
    const [ bankNameComplete, setBankNameComplete ] = useState(false);
    const [ bankContactFirstName, setBankContactFirstName ] = useState('');
    const [ bankContactLastName, setBankContactLastName ] = useState('');
    const [ bankContactNameComplete, setBankContactNameComplete ] = useState(false);
    const [ bankStreet, setBankStreet ] = useState('');
    const [ bankStreet2, setBankStreet2 ] = useState('');
    const [ bankStreetComplete, setBankStreetComplete ] = useState(false);
    const [ bankCity, setBankCity ] = useState('');
    const [ bankCityComplete, setBankCityComplete ] = useState('');
    const [ bankState, setBankState ] = useState('');
    const [ bankStateComplete, setBankStateComplete ] = useState(false);
    const [ bankZip, setBankZip ] = useState('');
    const [ bankZipComplete, setBankZipComplete ] = useState(false);
    const [ bankCountry, setBankCountry ] = useState('');
    const [ bankCountryComplete, setBankCountryComplete ] = useState(false);
    const [ bankTelephone, setBankTelephone ] = useState('');
    const [ bankTelephoneComplete, setBankTelephoneComplete ] = useState(false);
    const [ bankContactEmail, setBankContactEmail ] = useState('');
    const [ bankContactEmailComplete, setBankContactEmailComplete ] = useState(false);
    const [ bankAccountName, setBankAccountName ] = useState('');
    const [ bankAccountNameComplete, setBankAccountNameComplete ] = useState(false);
    const [ bankAccountNumber, setBankAccountNumber ] = useState('');
    const [ bankAccountNumberComplete, setBankAccountNumberComplete ] = useState(false);
    const [ bankRoutingNumber, setBankRoutingNumber ] = useState('');
    const [ bankRoutingNumberComplete, setBankRoutingNumberComplete ] = useState(false);
    const [ bankSwiftCode, setBankSwiftCode ] = useState('');
    const [ bankSwiftCodeComplete, setBankSwiftCodeComplete ] = useState(false);
    const [ bankAccountSelection, setBankAccountSelection ] = useState('Checking');
    const [ bankAccountSelectionComplete, setBankAccountSelectionComplete ] = useState(false);

/*
     // INTERNATIONAL WIRE STATE
    const [ intWireShown, setIntWireShown ] = useState(false);
    const [ intWireActive, setIntWireActive ] = useState(false);
    const [ intWire, setIntWire ] = useState();
    const [ intWireId, setIntWireId ] = useState();
    const [ intWireError, setIntWireError ] = useState('');

     W9 STATE
    const [ w9Shown, setW9Shown ] = useState(false);
    const [ w9Active, setW9Active ] = useState(false);
    const [ finishedW9, setFinishedW9 ] = useState();
    const [ w9Id, setW9Id ] = useState();
    const [ w9Error, setW9Error ] = useState('');
    */

    // Owner/s Or AUTHORIZED OFFICERS STATE
    const [ OwnersOrOfficersInformationActive, setOwnersOrInformationActive ] = useState('');
    const [ name1, setName1 ] = useState('');
    const [ name1Complete, setName1Complete ] = useState(false);
    const [ title, setTitle ] = useState('');
    const [ titleComplete, setTitleComplete ] = useState(false);
    const [ phone, setPhone ] = useState('');
    const [ phoneComplete, setPhoneComplete ] = useState(false);
    const [ homeAddress, setHomeAddress ] = useState('');
    const [ homeAddressComplete, setHomeAddressComplete ] = useState(false);
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ emailAddressComplete, setEmailAddressComplete ] = useState(false);
    const [ secondaryOwner, setSecondaryOwner ] = useState(false);
    const [ name2, setName2 ] = useState('');
    const [ name2Complete, setName2Complete ] = useState(false);
    const [ title2, setTitle2 ] = useState('');
    const [ title2Complete, setTitle2Complete ] = useState(false);
    const [ phone2, setPhone2 ] = useState('');
    const [ phone2Complete, setPhone2Complete ] = useState(false);
    const [ homeAddress2, setHomeAddress2 ] = useState('');
    const [ homeAddress2Complete, setHomeAddress2Complete ] = useState(false);
    const [ emailAddress2, setEmailAddress2 ] = useState('');
    const [ emailAddress2Complete, setEmailAddress2Complete ] = useState(false);


    /*
    //Bank Reference State
    const [ BankReferenceInformationActive, setBankReferenceInformationActive ] = useState(false);
    const [ bankNameReference, setBankNameReference ] = useState('');
    const [ bankNameReferenceComplete, setBankNameReferenceComplete ] = useState(false);
    const [ bankContactName, setBankContactName] = useState('');
    const [ bankContactNameReferenceComplete, setBankContactNameReferenceComplete ] = useState(false);
    const [ bankAddress, setBankAddress ] = useState('');
    const [ bankAddressComplete, setBankAddressComplete ] = useState(false);
    const [ bankPhone, setBankPhone ] = useState('');
    const [ bankPhoneComplete, setBankPhoneComplete ] = useState(false);
    const [ bankEmail, setBankEmail ] = useState('');
    const [ bankEmailComplete, setBankEmailComplete ] = useState(false);
    const [ nameOnAccount, setNameOnAccount ] = useState('');
    const [ nameOnAccountComplete, setNameOnAccountComplete ] = useState(false);
*/


    //Trade Reference State
    const [ TradeReferencesInformationActive, setTradeReferencesInformationActive ] = useState('');
    const [ companyName, setCompanyName ] = useState('');
    const [ companyNameComplete, setCompanyNameComplete ] = useState(false);
    const [ contactName, setContactName ] = useState('');
    const [ contactNameComplete, setContactNameComplete ] = useState(false);
    const [ tradeEmail, setTradeEmail ] = useState('');
    const [ tradeEmailComplete, setTradeEmailComplete ] = useState(false);
    const [ tradePhone, setTradePhone ]  = useState('');
    const [ tradePhoneComplete, setTradePhoneComplete ] = useState(false);
    const [ secondaryReference, setSecondaryReference ] = useState(false);
    const [ companyName2, setCompanyName2 ] = useState('');
    const [ companyName2Complete, setCompanyName2Complete ] = useState(false);
    const [ contactName2, setContactName2 ] = useState('');
    const [ contactName2Complete, setContactName2Complete ] = useState(false);
    const [ tradeEmail2, setTradeEmail2 ] = useState('');
    const [ tradeEmail2Complete, setTradeEmail2Complete ] = useState(false);
    const [ tradePhone2, setTradePhone2 ]  = useState('');
    const [ tradePhone2Complete, setTradePhone2Complete ] = useState(false);
    const [ thirdReference, setThirdReference ] = useState(false);
    const [ companyName3, setCompanyName3 ] = useState('');
    const [ companyName3Complete, setCompanyName3Complete ] = useState(false);
    const [ contactName3, setContactName3 ] = useState('');
    const [ contactName3Complete, setContactName3Complete ] = useState(false);
    const [ tradeEmail3, setTradeEmail3 ] = useState('');
    const [ tradeEmail3Complete, setTradeEmail3Complete ] = useState(false);
    const [ tradePhone3, setTradePhone3 ]  = useState('');
    const [ tradePhone3Complete, setTradePhone3Complete ] = useState(false);

    //Conditions State
    const [ conditionsComplete, setConditionsComplete ] = useState(false);
    const [ nameSign, setNameSign ] = useState('');
    const [ nameSignComplete, setNameSignComplete ] = useState(false);
    const [ titleSign, setTitleSign ] = useState('');
    const [ titleSignComplete, setTitleSignComplete ] = useState(false);
    const [ signature, setSignature ] = useState('');
    const [ signatureComplete, setSignatureComplete ] = useState(false);
    const [ date, setDate ]  = useState('');
    const [ dateComplete, setDateComplete ] = useState(false);
    const [ secondarySignature, setSecondarySignature ] = useState(false);
    const [ nameSign2, setNameSign2 ] = useState('');
    const [ nameSign2Complete, setNameSign2Complete ] = useState(false);
    const [ titleSign2, setTitleSign2 ] = useState('');
    const [ titleSign2Complete, setTitleSign2Complete ] = useState(false);
    const [ signature2, setSignature2 ] = useState('');
    const [ signature2Complete, setSignature2Complete ] = useState(false);
    const [ date2, setDate2 ]  = useState('');
    const [ date2Complete, setDate2Complete ] = useState(false);



    // Submitting State
    const [ formComplete, setFormComplete] = useState(false);
    const [ submitting, setSubmitting] =useState(false);
    const [ rejectionRecord, setRejectionRecord ] = useState();
    const [ formSubmissionValid, setFormSubmissionValid] = useState();








    useEffect(() => {
        if(w9Required) setFilesRequired(true);
    },[w9Required]);

    useEffect(() => {    // TRIGGER ACH & INT WIRE WINDOW SHOW
        if(!rejectionRecord){
            if(paymentMethod === 'ACH'){
                setAchActive(true);
                setAchShown(true);
                setIntWireActive(false);
                //setIntWireShown(false);
                setVoidedCheckActive(true);
                setCreditCardActive(false);
            }

             else if(paymentMethod === 'International Wire'){
                setIntWireActive(true);
               // setIntWireShown(true);
               setCreditCardActive(false);
                setAchActive(true);
                setAchShown(true);
                setCreditCardActive(false);
               // setVoidedCheckShown(false);
                setVoidedCheckActive(false);
                
            }

            else if(paymentMethod === 'Credit Card (5K or Less ONLY)'){
                setCreditCardActive(true);
                setAchActive(false);
                setVoidedCheckActive(false);
                setIntWireActive(false);

            }
            else{
                setCreditCardActive(false);
                setAchActive(false);
                setAchShown(false);
                //setVoidedCheckShown(false);
                setVoidedCheckActive(false);
                setIntWireActive(false);
                //setIntWireShown(false);
            };
        };
    },[paymentMethod]);

    useEffect(() => {    // TRIGGER Tax Cert WINDOW SHOW
        if(!rejectionRecord){

             if(taxExemptCert === 'Yes (Provide Certificate)'){
                setTaxCertActive(true);
                //setTaxCertShown(true);
            }
            else{
                setTaxCertActive(false);
                //setTaxCertShown(false);
            };
        };
    },[taxExemptCert]);    



    const checkFormFields = () => {
        let complete = true;

        if(fedEinSSn.length === 9){
            setFedEinSSnComplete(true);
        }
        else{
            setFedEinSSnComplete(false)
            complete = false;
        };

        if(dunsNo.length === 9) {
            setdunsNoComplete(true);
        }
        else{
            setdunsNoComplete(false);
            complete = false;
        };

        if(website.length > 4) {
            setWebsite(true);
        }
        else{
            setWebsite(false);
            complete = false;
        };

        if(legalName.length < 3) setLegalNameComplete(true)
         else{
            setLegalNameComplete(false)
            complete = false;

        };   
            
        if(tradeNameDBA.length > 2) {
            setTradeNameDBAComplete(true);
        }
        else{
            setTradeNameDBAComplete(false)
            complete = false;
        };

        if(street.length > 5) {
            setStreetComplete(true);
        }
        else{
            setStreetComplete(false)
            complete = false;
        };

        if(shippingStreet.length > 5) {
            setShippingStreetComplete(true);
        }
        else{
            setShippingStreetComplete(false)
            complete = false;
        };

        if(city.length > 2) {
            setCityComplete(true);
        }
        else{
            setCityComplete(false)
            complete = false;
        };

        if(shippingCity.length > 2) {
            setShippingCityComplete(true);
        }
        else{
            setShippingCityComplete(false)
            complete = false;
        };

        if(state.length > 1) {
            setStateComplete(true);
        }
        else{
            setStateComplete(false)
            complete = false;
        };

        if(shippingState.length > 1) {
            setShippingStateComplete(true);
        }
        else{
            setShippingStateComplete(false)
            complete = false;
        };

        if(zip.length === 5){
            setZipComplete(true);
        }
        else{
            setZipComplete(false)
            complete = false;
        };

        if(shippingZip.length === 5){
            setShippingZipComplete(true);
        }
        else{
            setShippingZipComplete(false)
            complete = false;
        };

        if(primaryContactNumber.length === 10){
            setPrimaryContactNumberComplete(true);
        }
        else{
            setPrimaryContactNumberComplete(false);
            complete = false;
        };

        if(checkEmail(primaryContactEmail)) setPrimaryContactEmailComplete(true);
        else{
            setPrimaryContactEmailComplete(false);
            complete = false;
        };

        if(contactNameAP.length > 2){
            setContactNameAPComplete(true);
        }
        else{
            setContactNameAPComplete(false);
            complete = false;
        };

        if(phoneAP.length === 10){
            setPhoneAPComplete(true);
        }
        else{
            setPhoneAPComplete(false);
            complete = false;
        };

        if(checkEmail(contactEmailAP)) setContactEmailAPComplete(true);
        else{
            setContactEmailAPComplete(false);
            complete = false;
        };

        if(name1.length > 2){
            setName1Complete(true);
        }
        else{
            setName1Complete(false);
            complete = false;
        };

        if(title.length > 2){
            setTitleComplete(true);
        }
        else{
            setTitleComplete(false);
            complete = false;
        };

        if(phone.length === 10){
            setPhoneComplete(true);
        }
        else{
            setPhoneComplete(false);
            complete = false;
        };

        if(homeAddress.length > 8){
            setHomeAddressComplete(true);
        }
        else{
            setHomeAddressComplete(false);
            complete = false;
        };

        if(checkEmail(emailAddress)) setEmailAddressComplete(true);
        else{
            setEmailAddressComplete(false);
            complete = false;
        };

        if(secondaryOwner){
            if(name2.length > 2){
                setName2Complete(true);
            }
            else{
                setName2Complete(false);
                complete = false;
            };
    
            if(title2.length > 2){
                setTitle2Complete(true);
            }
            else{
                setTitle2Complete(false);
                complete = false;
            };

            if(phone2.length === 10){
                setPhone2Complete(true);
            }
            else{
                setPhone2Complete(false);
                complete = false;
            };
    
            if(homeAddress2.length > 8){
                setHomeAddress2Complete(true);
            }
            else{
                setHomeAddress2Complete(false);
                complete = false;
            };
    
            if(checkEmail(emailAddress2)) setEmailAddress2Complete(true);
            else{
                setEmailAddress2Complete(false);
                complete = false;
            };
        }


        if(companyName.length > 2){
            setCompanyNameComplete(true);
        }
        else{
            setCompanyNameComplete(false);
            complete = false;
        };

        if(contactName.length > 2){
            setContactNameComplete(true);
        }
        else{
            setContactNameComplete(false);
            complete = false;
        };

        if(checkEmail(tradeEmail)) setTradeEmailComplete(true);
            else{
                setTradeEmailComplete(true);
                complete = false;
            };

        if(tradePhone.length === 10){
            setTradePhoneComplete(true);
        }
        else{
            setTradePhoneComplete(false);
            complete = false;
        };  
        
        if(secondaryReference){
            
            if(companyName2.length > 2){
                setCompanyName2Complete(true);
            }
            else{
                setCompanyName2Complete(false);
                complete = false;
            };
    
            if(contactName2.length > 2){
                setContactName2Complete(true);
            }
            else{
                setContactName2Complete(false);
                complete = false;
            };
    
            if(checkEmail(tradeEmail2)) setTradeEmail2Complete(true);
                else{
                    setTradeEmail2Complete(true);
                    complete = false;
                };
    
            if(tradePhone2.length === 10){
                setTradePhone2Complete(true);
            }
            else{
                setTradePhone2Complete(false);
                complete = false;
            };
        }
            if(thirdReference){

                if(companyName3.length > 2){
                    setCompanyName3Complete(true);
                }
                else{
                    setCompanyName3Complete(false);
                    complete = false;
                };
        
                if(contactName3.length > 2){
                    setContactName3Complete(true);
                }
                else{
                    setContactName3Complete(false);
                    complete = false;
                };
        
                if(checkEmail(tradeEmail3)) setTradeEmail3Complete(true);
                    else{
                        setTradeEmail3Complete(true);
                        complete = false;
                    };
        
                if(tradePhone3.length === 10){
                    setTradePhone3Complete(true);
                }
                else{
                    setTradePhone3Complete(false);
                    complete = false;
                };  
            }

            if(paymentMethod === 'ACH'){
                if(bankName.length > 2) setBankNameComplete(true);
                else{
                    setBankNameComplete(false);
                    complete = false;
                };
        
                if(bankContactFirstName.length > 2 && bankContactLastName.length > 2) setBankContactNameComplete(true);
                else{
                    setBankContactNameComplete(false);
                    complete = false;
                };
        
                if(bankStreet.length > 2) setBankStreetComplete(true);
                else{
                    setBankStreetComplete(false);
                    complete = false;
                };
        
                if(bankCity.length > 2) setBankCityComplete(true);
                else{
                    setBankCityComplete(false);
                    complete = false;
                };
        
                if(bankState.length > 1) setBankStateComplete(true);
                else{
                    setBankStateComplete(false);
                    complete = false;
                };
        
                if(bankZip.length === 5) setBankZipComplete(true);
                else{
                    setBankZipComplete(false);
                    complete = false;
                };
        
                if(bankZip.length === 5) setBankZipComplete(true);
                else{
                    setBankZipComplete(false);
                    complete = false;
                };
        
                if(bankTelephone.length === 10) setBankTelephoneComplete(true);
                else{
                    setBankTelephoneComplete(false);
                    complete = false;
                };
                
                if(checkEmail(bankContactEmail)) setBankContactEmailComplete(true);
                else{
                    setBankContactEmailComplete(false);
                    complete = false;
                };
        
                if(bankAccountName.length > 2) setBankAccountNameComplete(true);
                else{
                    setBankAccountNameComplete(false);
                    complete = false;
                };
        
                if(bankAccountNumber.length > 7) setBankAccountNumberComplete(true);
                else{
                    setBankAccountNumberComplete(false);
                    complete = false;
                };
        
                if(bankRoutingNumber.length === 9) setBankRoutingNumberComplete(true);
                else{
                    setBankRoutingNumberComplete(false);
                    complete = false;
                };
        
                if(bankAccountSelection && bankAccountSelection !== '') setBankAccountSelectionComplete(true);
                else{
                    setBankAccountSelectionComplete(false);
                    complete = false;
                };
            }

            if(paymentMethod === 'Credit Card (5K or Less ONLY)'){
                if(nameOnCard.length > 2) {
                    setNameOnCardComplete(true);
                }
                else{
                    setNameOnCardComplete(false);
                    complete = false;
                }
            }

            if(cardNumber.length === 16){
                setCardNumberComplete(true);
            }
            else{
                setCardNumberComplete(false);
                complete = false;
            }

            if(expDate.length > 2){
                setExpDateComplete(true);
            }
            else{
                setExpDateComplete(false);
                complete = false;
            };

            if(cvv.length === 3){
                setCvvComplete(true);
            }
            else{
                setCvvComplete(false);
                complete = false;
            };

            if(cardZip === 5){
                setCardZipComplete(true);
            }
            else{
                setCardZipComplete(false);
                complete = false;
            }

            if(signature.length > 3) setSignatureComplete(true);
            else{
                setSignatureComplete(false);
                complete = false;
            };
    
            if(nameSign.length > 3) setNameSignComplete(true);
            else{
                setNameSignComplete(false);
                complete = false;
            };

            if(titleSign.length > 2) {
                setTitleSignComplete(true);
            }
            else{
                setTitleSignComplete(false);
                complete = false;
            };

            if(date.length > 2) {
                setDateComplete(true);
            }
            else{
                setDateComplete(false);
                complete = false;
            }

            if(secondarySignature) {
                
                if(nameSign2.length > 3) setNameSign2Complete(true);
                else{
                    setNameSign2Complete(false);
                    complete = false;
                };

                if(titleSign2.length > 2) {
                    setTitleSign2Complete(true);
                }
                else{
                    setTitleSign2Complete(false);
                    complete = false;
                };

                if(signature2.length > 3) setSignature2Complete(true);
                else{
                    setSignature2Complete(false);
                    complete = false;
                };
                if(date2.length > 2){
                    setDate2Complete(true);
                }
                else{
                    setDate2Complete(false);
                    complete = false;
                };
            }

        return complete;
    }

    useEffect(() => {
        if(!rejectionRecord){
            setFormComplete(checkFormFields());
        };
    },[fedEinSSn,dunsNo,website,legalName,tradeNameDBA,street,city,state,zip,shippingStreet,shippingCity,shippingState,shippingZip, ])



    

    const customerInformationProps = {
        setFedEinSSn:setFedEinSSn,
        fedEinSSn:fedEinSSn,
        fedEinSSnComplete,fedEinSSnComplete,
        setDunsNo:setDunsNo,
        dunsNo:dunsNo,
        dunsNoComplete:dunsNoComplete,
        setWebsite:setWebsite,
        website:website,
        websiteComplete:websiteComplete,
        setLegalName:setLegalName,
        legalName:legalName,
        legalNameComplete:legalNameComplete,
        setTradeNameDBA:setTradeNameDBA,
        tradeNameDBA:tradeNameDBA,
        tradeNameDBAComplete:tradeNameDBAComplete,
        setStreet:setStreet,
        street:street,
        street2:street2,
        setStreet2:setStreet2,
        streetComplete:streetComplete,
        setCity:setCity,
        city:city,
        cityComplete:cityComplete,
        setState:setState,
        state:state,
        stateComplete:stateComplete,
        setZip:setZip,
        zip:zip,
        zipComplete:zipComplete,
        setCountry:setCountry,
        country:country,
        setShippingStreet:setShippingStreet,
        shippingStreet:shippingStreet,
        shippingStreet2:shippingStreet2,
        setShippingStreet2:setShippingStreet2,
        shippingStreetComplete:shippingStreetComplete,
        setShippingCity:setShippingCity,
        shippingCity:shippingCity,
        shippingCityComplete:shippingCityComplete,
        setShippingState:setShippingState,
        shippingState:shippingState,
        shippingStateComplete:shippingStateComplete,
        setShippingZip:setShippingZip,
        shippingZip:shippingZip,
        shippingZipComplete:shippingZipComplete,
        setShippingCountry:setShippingCountry,
        shippingCountry:shippingCountry,
        setPrimaryContactName:setPrimaryContactName,
        primaryContactName:primaryContactName,
        primaryContactNameComplete:primaryContactNameComplete,
        setPrimaryContactNumber:setPrimaryContactNumber,
        primaryContactNumber:primaryContactNumber,
        primaryContactNumberComplete:primaryContactNumberComplete,
        setPrimaryContactEmail:setPrimaryContactEmail,
        primaryContactEmail:primaryContactEmail,
        primaryContactEmailComplete:primaryContactEmailComplete,
        taxExemptOptions:taxExemptOptions,
        setTaxExemptCert:setTaxExemptCert,
        taxExemptCert:taxExemptCert,
        taxExemptCertComplete:taxExemptCertComplete,
        contactNameAP:contactNameAP,
        setContactNameAP:setContactNameAP,
        contactNameAPComplete:contactNameAPComplete,
        setPhoneAP:setPhoneAP,
        phoneAP:phoneAP,
        phoneAPComplete:phoneAPComplete,
        setContactEmailAP:setContactEmailAP,
        contactEmailAP:contactEmailAP,
        contactEmailAPComplete:contactEmailAPComplete,
        paymentOptions:paymentOptions,
        setPaymentMethod:setPaymentMethod,
        paymentMethod:paymentMethod,
        paymentMethodComplete:paymentMethodComplete,
        businessTypeOptions:businessTypeOptions,
        setBusinessTypeSelection:setBusinessTypeSelection,
        businessTypeSelection:businessTypeSelection,
        businessTypeSelectionComplete:businessTypeSelectionComplete,
        setStateOfInc:setStateOfInc,
        stateOfInc:stateOfInc,
        stateOfIncComplete:stateOfIncComplete,
        //w9Options:w9Options,
        //setW9:setW9,
        //w9:w9,
        //w9Complete:w9Complete
    };


    const OwnersOrOfficersInformationProps = {
        setName1:setName1,
        name1:name1,
        name1Complete:name1Complete,
        setTitle:setTitle,
        title:title,
        titleComplete:titleComplete,
        setPhone:setPhone,
        phone:phone,
        phoneComplete:phoneComplete,
        setHomeAddress:setHomeAddress,
        homeAddress:homeAddress,
        homeAddressComplete:homeAddressComplete,
        setEmailAddress:setEmailAddress,
        emailAddress:emailAddress,
        emailAddressComplete:emailAddressComplete,
        setSecondaryOwner: setSecondaryOwner,
        secondaryOwner: secondaryOwner,
        setName2:setName2,
        name2:name2,
        name2Complete:name2Complete,
        setTitle2:setTitle2,
        title2:title2,
        title2Complete:title2Complete,
        setPhone2:setPhone2,
        phone2:phone2,
        phoneC2omplete:phone2Complete,
        setHomeAddress2:setHomeAddress2,
        homeAddress2:homeAddress2,
        homeAddress2Complete:homeAddress2Complete,
        setEmailAddress2:setEmailAddress2,
        emailAddress2:emailAddress2,
        emailAddress2Complete:emailAddress2Complete,
    };

    const CreditCardInformationProps = {
        setNameOnCard:setNameOnCard,
        nameOnCard:nameOnCard,
        nameOnCardComplete:nameOnCardComplete,
        setCardNumber:setCardNumber,
        cardNumber:cardNumber,
        cardNumberComplete:cardNumberComplete,
        setExpDate:setExpDate,
        expDate:expDate,
        expDateComplete:expDateComplete,
        setCvv:setCvv,
        cvv:cvv,
        cvvComplete:cvvComplete,
        setCardZip:setCardZip,
        cardZip:cardZip,
        cardZipComplete:cardZipComplete,
    };

    const achProps = {
        achActive:achActive,
        setBankName:setBankName,
        bankName:bankName,
        bankNameComplete:bankNameComplete,
        setBankContactFirstName:setBankContactFirstName,
        bankContactFirstName:bankContactFirstName,
        setBankContactLastName:setBankContactLastName,
        bankContactLastName:bankContactLastName,
        bankContactNameComplete:bankContactNameComplete,
        setBankStreet:setBankStreet,
        bankStreet:bankStreet,
        setBankStreet2:setBankStreet2,
        bankStreet2:bankStreet2,
        bankStreetComplete:bankStreetComplete,
        setBankCity:setBankCity,
        bankCity:bankCity,
        bankCityComplete:bankCityComplete,
        setBankState:setBankState,
        bankState:bankState,
        bankStateComplete:bankStateComplete,
        setBankZip:setBankZip,
        bankZip:bankZip,
        bankZipComplete:bankZipComplete,
        setBankCountry:setBankCountry,
        bankCountry:bankCountry,
        bankCountryComplete:bankCountryComplete,
        setBankTelephone:setBankTelephone,
        bankTelephone:bankTelephone,
        bankTelephoneComplete:bankTelephoneComplete,
        setBankContactEmail:setBankContactEmail,
        bankContactEmail:bankContactEmail,
        bankContactEmailComplete:bankContactEmailComplete,
        setBankAccountName:setBankAccountName,
        bankAccountName:bankAccountName,
        bankAccountNameComplete:bankAccountNameComplete,
        setBankAccountNumber:setBankAccountNumber,
        bankAccountNumber:bankAccountNumber,
        bankAccountNumberComplete:bankAccountNumberComplete,
        setBankRoutingNumber:setBankRoutingNumber,
        bankRoutingNumber:bankRoutingNumber,
        bankRoutingNumberComplete:bankRoutingNumberComplete,
        setBankSwiftCode:setBankSwiftCode,
        bankSwiftCode:bankSwiftCode,
        bankSwiftCodeComplete:bankSwiftCodeComplete,
        setBankAccountSelection:setBankAccountSelection,
        bankAccountSelection:bankAccountSelection,
        bankAccountSelectionComplete:bankAccountSelectionComplete,
    };

  

    const TradeReferencesInformationProps = {
        setCompanyName:setCompanyName,
        companyName:companyName,
        companyNameComplete:companyNameComplete,
        setContactName:setCompanyName,
        contactName:contactName,
        contactNameComplete:contactNameComplete,
        setTradeEmail:setTradeEmail,
        tradeEmail:tradeEmail,
        tradeEmailComplete:tradeEmailComplete,
        setTradePhone:setTradePhone,
        tradePhone:tradePhone,
        tradePhoneComplete:tradePhoneComplete,
        setSecondaryReference:setSecondaryReference,
        secondaryReference:secondaryReference,
        setCompanyName2:setCompanyName2,
        companyName2:companyName2,
        companyName2Complete:companyName2Complete,
        setContactName2:setCompanyName2,
        contactName2:contactName2,
        contactName2Complete:contactName2Complete,
        setTradeEmail2:setTradeEmail2,
        tradeEmail2:tradeEmail2,
        tradeEmail2Complete:tradeEmail2Complete,
        setTradePhone2:setTradePhone2,
        tradePhone2:tradePhone2,
        tradePhone2Complete:tradePhone2Complete,
        setThirdReference:setThirdReference,
        thirdReference:thirdReference,
        setCompanyName3:setCompanyName3,
        companyName3:companyName3,
        companyName3Complete:companyName3Complete,
        setContactName3:setCompanyName3,
        contactName3:contactName3,
        contactName3Complete:contactName3Complete,
        setTradeEmail3:setTradeEmail3,
        tradeEmail3:tradeEmail3,
        tradeEmail3Complete:tradeEmail3Complete,
        setTradePhone3:setTradePhone3,
        tradePhone3:tradePhone3,
        tradePhone3Complete:tradePhone3Complete
    };

    const ConditionsAndAuthorizationsProps = {
        setConditionsComplete:setConditionsComplete,
        conditionsComplete:conditionsComplete,
        setNammeSign:setNameSign,
        nameSign:nameSign,
        nameSignComplete:nameSignComplete,
        setTitleSign:setTitleSign,
        titleSign:titleSign,
        titleSignComplete:titleSignComplete,
        ///signature:signature,
        signatureComplete:signatureComplete,
        setDate:setDate,
        date:date,
        dateComplete:dateComplete,
        setSecondarySignature:setSecondarySignature,
        secondarySignature:secondarySignature,
        setNameSign2:setNameSign2,
        NameSign2:nameSign2,
        NameSign2Complete:nameSign2Complete,
        setTitleSign2:setTitleSign2,
        titleSign2:titleSign2,
        titleSign2Complete:titleSign2Complete,
        setSignature2:setSignature2,
        //signature2:signature2,
        signature2Complete:signature2Complete,
        setDate2:setDate2,
        date2:date2,
        date2Complete:date2Complete

        
    };    



    // CREATE JSON TO SEND TO BACK END
    const createCustomerRequestObject = () => {
        const primaryContact = {
            
        }
        
        const customerObject = {    
            fedEinSSn:fedEinSSn,
            dunsNo:dunsNo,
            website:website,
            legalName:legalName,
            tradeNameDBA:tradeNameDBA,
            street:street,
            street2:street2,
            city:city,
            state:state,
            zip:zip,
            country:country,
            shippingStreet:shippingStreet,
            shippingStreet2:shippingStreet2,
            shippingCity:shippingCity,
            shippingState:shippingState,
            shippingZip:shippingZip,
            shippingCountry:shippingCountry,
            contactName:contactName,

            primaryContact: primaryContact,

            contactNameAP:contactNameAP,
            phoneAP:phoneAP,
            contactEmailAP:contactEmailAP,
            paymentMethod:paymentMethod,
            stateOfInc:stateOfInc,
            name1:name1,
            title:title,
            phone:phone,
            homeAddress:homeAddress,
            emailAddress:emailAddress,
            name2:name2,
            title2:title2,
            phone2:phone2,
            homeAddress2:homeAddress2,
            emailAddress2:emailAddress2,
            companyName:companyName,
            contactName:contactName,
            tradeEmail:tradeEmail,
            tradePhone:tradePhone,
            companyName2:companyName2,
            contactName2:contactName2,
            tradeEmail2:tradeEmail2,
            tradePhone2:tradePhone2,
            companyName3:companyName3,
            contactName3:contactName3,
            tradeEmail3:tradeEmail3,
            tradePhone3:tradePhone3,
            nameSign:nameSign,
            titleSign:titleSign,
            signature:signature,
            date:date,
            nameSign2:nameSign2,
            titleSign2:titleSign2,
            signature2:signature2,
            date2:date2,
        };

        return customerObject;
    };
    


    // WAITS FOR ALL FILES TO BE UPLOADED AND THEN CALLS TO SEND OBJECT TO BACK END
    useEffect(() => {
        let complete = true;

        if(!checkFormFields()) complete = false;

        if(w9 && !w9Id) complete = false;
        if(taxCert && !taxCertId) complete = false;
        if(intWire && !intWireId) complete = false;
        if(voidedCheckFile && !voidedCheckFileId) complete = false;

        if(complete) submitCustomerRequest(createCustomerRequestObject(),setFormSubmissionValid);
    },[]);


    const submitForm = () => {
        setSubmitting(true);

        //Submit w9
        sendW9(w9,`${tradeNameDBA}-W9-${getDate()}.pdf`,setW9Id,setW9Error);

        //Submit Voided Check (if Required)


        //Submit Internation Wire (if Required)
        sendInternationalWire(intWire,`${tradeNameDBA}-Int Wire-${getDate()}.pdf`,setIntWireId,setIntWireError)

        //Submit Tax Exemption (if Required)

    };


    // RENDER NEW VENDOR PRIMARY
    return(
        <div className='External-Form-Window'>
            {   !submitting && !rejectionRecord &&
                <div className='External-Form-Page'>
                    <FormHeader formEmail={formEmail}/>

                    <CustomerInformation {...customerInformationProps} />

                    {   creditCardActive &&
                        <CreditCardInformation {...CreditCardInformationProps} />
                    }

                    {   achActive &&
                        <AchInformation {...achProps} />
                    }

                    <OwnersOrOfficersInformation {...OwnersOrOfficersInformationProps} />                

                    <TradeReferencesInformation {...TradeReferencesInformationProps} />

                    <RequiredFilesSection paymentMethod={paymentMethod} setW9={setW9} w9Active={w9Active} taxCertActive={taxCertActive} intWireActive={intWireActive} voidedCheckActive={voidedCheckActive} 
                    setFinishedW9={setFinishedW9} setTaxCert={setTaxCert} setIntWire={setIntWire} setVoidedCheckFile={setVoidedCheckFile}/>

                    <ConditionsAndAuthorizations {...ConditionsAndAuthorizationsProps} />

                    {   formComplete &&
                        <button onClick={() => submitForm()} className='External-Customer-Submit External-Customer-Button'>Submit Form</button>
                    }


                    <img src={'https://www.positivessl.com/images/seals/positivessl_trust_seal_md_167x42.png'} className='External-Vendor-SSL'/>

                </div>
            }
            {   submitting && !rejectionRecord &&
                <div>

                </div>
            }

            {   rejectionRecord &&
                <div>

                </div>
            }
            
        </div>
    )
};

export default NewCustomer;


/*
{
    "CustomerObject" : {
        "fedEinSsn" : "String",
        "dunsNo" : "String",
        "website" : "String",
        "legalName" : "String",
        "tradeName" : "String",
        "address" : {
            "street": "String",
            "street2": "String",
            "city": "String",
            "state": "String",
            "zip": "String",
            "country": "String"
        },
        "shippingAddress" : {
            "street": "String",
            "street2": "String",
            "city": "String",
            "state": "String",
            "zip": "String",
            "country": "String"
        },
        "primaryContact" : {
            "firstName": "String",
            "lastName": "String",
            "number": "String",
            "extension": "String",
            "title": "String",
            "address" : {
                "street": "String",
                "street2": "String",
                "city": "String",
                "state": "String",
                "zip": "String",
                "country": "String"
            }
        },
        "phoneAP" : "String",
        "emailAP" : "String",
        "paymentMethod" : "String",
        "incState" : "String",
        "bankAccount" : {
            "name" : "String",
            "contact" : {
                "firstName": "String",
                "lastName": "String",
                "number": "String",
                "extension": "String",
                "title": "String",
                "address" : {
                    "street": "String",
                    "street2": "String",
                    "city": "String",
                    "state": "String",
                    "zip": "String",
                    "country": "String"
                }
            },
            "address" : {
                "street": "String",
                "street2": "String",
                "city": "String",
                "state": "String",
                "zip": "String",
                "country": "String"
            },
            "accountName": "String",
            "accountNumber": "String",
            "swiftCode": "String",
            "accountType": "String"
        },
        "authorizedOfficers" : [
            {
                "firstName": "String",
                "lastName": "String",
                "number": "String",
                "extension": "String",
                "title": "String",
                "address" : {
                    "street": "String",
                    "street2": "String",
                    "city": "String",
                    "state": "String",
                    "zip": "String",
                    "country": "String"
                }
            }
        ],
        "creditCard" : {
            "name" : "String",
            "number" : "String",
            "expiration" : "Date",
            "billingZip" : "String",
            "securityCode" : "String"
        },
        "tradeReferences" : [
            {
                "firstName": "String",
                "lastName": "String",
                "number": "String",
                "extension": "String",
                "title": "String",
                "address" : {
                    "street": "String",
                    "street2": "String",
                    "city": "String",
                    "state": "String",
                    "zip": "String",
                    "country": "String"
                }
            }
        ],
        "signer1Name" : "String",
        "signer1Title" : "String",
        "signer2Name" : "String",
        "signer2Title" : "String",
        
        "w9File" : "Integer",
        "voidedCheckFile" : "Integer",
        "internationalWireFile" : "Integer",
        "creditCardFile" : "Integer",
        "creditReferencesSheetFile" : "Integer"
    }
}
*/