import { useState, useEffect, useContext } from 'react';
import {Context} from '../Context';
import './VendorRequests.css'
import newVendorRequestService from '../Services/VendorRequestService';
import moment from 'moment';
import { FaFlag, FaBox, FaHammer,FaSearch,FaArrowUp,FaArrowDown,FaFilePdf,FaCheck } from "react-icons/fa";
import { RiGovernmentFill } from "react-icons/ri";
import { GiScales } from "react-icons/gi";
import { MdPayments } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";

import { RotatingLines } from "react-loader-spinner";


const organizeDate = (date) => {
    return moment(date).format("MMM Do YYYY");
};

const DropDownSelection = ({title, options, setTo, width, initializedValue}) => {
    const inputCssProps = {
        '--inputWidth': `${width}`
    };

    const selectOption = (value) => {
         setTo(value);
    };

    return(
        <div className='External-Form-Input' style={inputCssProps}>
        <div className='External-Form-Input-Title'>
            {title}
        </div>

        <select className='External-Form-Dropdown' onChange={(e) => selectOption(e.target.value)}>
            <option value={true}>{initializedValue}</option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        
    </div>
    )
};

const SearchBar = ({setSearchInput,searchList, selectSearchItem}) => {
    const [ focused, setFocused ] = useState(false);

    const handleSearchItemClick = (businessName) => {
        selectSearchItem(businessName);
        setFocused(false);
        document.getElementById('myInput').value = ''
        setSearchInput('');
    };

    return(      
        <div className='Vendor-Request-Header-Search' onClick={() => setFocused(true)}  onMouseLeave={() => setFocused(false)}>
            <FaSearch  className='Vendor-Request-Header-Search-Icon'/>
            <input autoComplete="off"  onChange={((e) => setSearchInput(e.target.value))} id="myInput" className='Vendor-Request-Header-Search-Text' placeholder='Search Vendors'/>

            {   focused && searchList && 
            <div className='Vendor-Request-Header-Search-List'>
            {   
                searchList.map((object, key) => (
                    <div key={key} className='Vendor-Request-Header-Search-Term' onClick={(event) => {handleSearchItemClick(object.business_name); event.stopPropagation()}}>
                        {object.business_name}
                    </div>
                ))
            }
            </div>
            }

        </div>
    )
};

const ActiveSelector = ({activeSort,setActiveSort}) => {
    return(
        <div className='Vendor-Request-Header-Active'>

            {   activeSort && activeSort !== 'both' &&
            <div className='Vendor-Request-Header-Select Selected-Active'>
                Active
            </div>
            }
            { activeSort === 'both' &&
            <div className='Vendor-Request-Header-Select ' onClick={() => setActiveSort(true)}>
                Active
            </div>
            }
            {   !activeSort &&
            <div className='Vendor-Request-Header-Select ' onClick={() => setActiveSort(true)}>
                Active
            </div>
            }


            <div className='Vendor-Request-Header-Select-Divider'/>

            {   !activeSort &&
            <div className='Vendor-Request-Header-Select Selected-Active'>
                InActive
            </div>
            }
            {   activeSort &&
            <div className='Vendor-Request-Header-Select' onClick={() => setActiveSort(false)}>
                InActive
            </div>  
            }

            <div className='Vendor-Request-Header-Select-Divider'/>

            {   activeSort === 'both' &&
            <div className='Vendor-Request-Header-Select Selected-Active'>
                Both
            </div>
            }
            {   activeSort !== 'both' &&
            <div className='Vendor-Request-Header-Select' onClick={() => setActiveSort('both')}>
                Both
            </div>  
            }
        </div>
    )
};

const ControlBar = ({resetList,activeSort,setActiveSort}) => {
    return(
        <div className='Vendor-Request-Header-Controls'>
            <ActiveSelector activeSort={activeSort} setActiveSort={setActiveSort}/>
            <button onClick={() => resetList()} className='Vendor-Request-Header-All-Button'>Default View</button>
        </div>
    )
};

const SortDirectionIndicator = ({currentSelection, validSelection, direction}) => {
    if(currentSelection === validSelection){
        if(direction === 'ASC') return <FaArrowUp />
        else return <FaArrowDown />
    }

};

const BottomHeader = ({screenWidth, sortDirection, orderBy, handleOrderClick}) => {
    return(
        <div className='Vendor-Request-Bottom-Header'>

            <div className='Vendor-Request-Tile-Information'>
                <div className='Vendor-Request-Tile-Data Header-Title' onClick={() => handleOrderClick('vendor_type')}>
                    Request Type <SortDirectionIndicator currentSelection={orderBy} validSelection={'vendor_type'} direction={sortDirection}/>
                </div>
                <div className='Vendor-Request-Tile-Data Header-Title' onClick={() => handleOrderClick('business_name')}>
                    Business Name <SortDirectionIndicator currentSelection={orderBy} validSelection={'business_name'} direction={sortDirection}/>
                </div>
                {   screenWidth > 1000 &&
                <div className='Vendor-Request-Tile-Data Header-Title' onClick={() => handleOrderClick('city')}>
                    Business Location <SortDirectionIndicator currentSelection={orderBy} validSelection={'city'} direction={sortDirection}/>
                </div>
                }
                <div className='Vendor-Request-Date Header-Title' onClick={() => handleOrderClick('date')}>
                    Date Received <SortDirectionIndicator currentSelection={orderBy} validSelection={'date'} direction={sortDirection}/>
                </div>
            </div>

        </div>
    )
};

const VendorRequestHeader = ({vendorHeaderObject}) => {
    const {setSearchInput,sortDirection,flipSortOrder,screenWidth, orderBy, handleOrderClick, searchList, selectSearchItem,resetList, 
        activeSort, setActiveSort} = vendorHeaderObject;
    return(
        <div className='Vendor-Request-Header'>
            <div className='Vendor-Request-Top-Header'>
                <SearchBar setSearchInput={setSearchInput} searchList={searchList} selectSearchItem={selectSearchItem}/>
                <ControlBar resetList={resetList} activeSort={activeSort} setActiveSort={setActiveSort}/>
            </div>
            <BottomHeader screenWidth={screenWidth} sortDirection={sortDirection} orderBy={orderBy} handleOrderClick={handleOrderClick} />

        </div>
    )
};

const TileHoverPanel = ({vendor,toggleFlag}) => {
    return (
        <div className='Vendor-Request-Flag-Frame'>
            {   vendor.flag &&
                <FaFlag className='Vendor-Request-Flag-Checked' onClick={(event) => {toggleFlag(vendor.id); event.stopPropagation()}}/>
            }
            {   !vendor.flag &&
                <FaFlag className='Vendor-Request-Flag-Unchecked' onClick={(event) => {toggleFlag(vendor.id); event.stopPropagation()}}/>
            }
            
        </div>
    )
};

const VendorTile = ({vendor, selectVendorRecord, toggleFlag, screenWidth}) => {
    const [ hover, setHover ] = useState();

    return(
        <div className='Vender-Request-Tile' onClick={() => selectVendorRecord(vendor.id)} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            { vendor.vendor_type === 'Supplier' &&
                <FaBox className='Vendor-Request-Tile-Image'/>
            }
            { vendor.vendor_type === 'Subcontractor' &&
                <FaHammer className='Vendor-Request-Tile-Image'/>
            }
            { vendor.vendor_type === 'Government' &&
                <RiGovernmentFill className='Vendor-Request-Tile-Image'/>
            }
            { vendor.vendor_type === 'Attorney' &&
                <GiScales className='Vendor-Request-Tile-Image'/>
            }
            { vendor.vendor_type === 'Rent' &&
                <MdPayments className='Vendor-Request-Tile-Image'/>
            }

            <div className='Vendor-Request-Tile-Information'>
                <div className='Vendor-Request-Tile-Data'>
                    {`${vendor.form_type} ${vendor.vendor_type} Request`}
                </div>
                <div className='Vendor-Request-Tile-Data'>
                    {vendor.business_name}
                </div>
                {   screenWidth > 1000 &&
                <div className='Vendor-Request-Tile-Data'>
                    {`${vendor.city}, ${vendor.state}`}
                </div>
                }

                <div className='Vendor-Request-Date'>
                    {organizeDate(vendor.date)}
                </div>
                {   screenWidth > 1000 &&
                <>
                {hover && <TileHoverPanel vendor={vendor} toggleFlag={toggleFlag}/>}
                {!hover &&
                    <div className='Vendor-Request-Flag-Frame'>
                        {   vendor.flag &&
                            <FaFlag className='Vendor-Request-Flag'/>
                        }
                    </div>
                }
                </>
                }
 
            </div>
            
        </div>
    )
};

const EmptyVendorDisplay = () => {
    return(
        <div className='Vendor-Request-No-Display'>
            No Records to Display.
        </div>
    )
};

const VendorRequestDisplay = ({vendorRequestObject}) => {
    const { vendorList, selectVendorRecord, toggleFlag, screenWidth } = vendorRequestObject;
    return(
        <div className='Vendor-Request-Display'> 
            {   vendorList &&
                vendorList.map((vendor, key) => (
                    <VendorTile key={key} vendor={vendor} selectVendorRecord={selectVendorRecord} toggleFlag={toggleFlag} screenWidth={screenWidth}/>
                ))
            }
            {   vendorList.length < 1 &&
                <EmptyVendorDisplay />
            }
        </div>
    )
};

const ConfirmationWindow = ({title, confirm, deny}) => {
    return(
        <div className='Vendor-Request-Confirmation'>
            <div className='Vendor-Request-Confirmation-Header'>
                {title}
            </div>
            <div className='Vendor-Request-Confirmation-Button-Group'>
            <button onClick={() => confirm()} className='Vendor-Request-Confirmation-Button Vendor-Request-Confirm-Button'>Yes</button>
            <button onClick={() => deny()} className='Vendor-Request-Confirmation-Button Vendor-Request-Deny-Button'>No</button>
            </div>

        </div>
    )
};

const ApprovalVendorWindow = ({confirm, deny,approvalProps}) => {
    const approveConfirmationTitle = 'Please complete the form below to approve this vendor.';
    const [complete,setComplete] = useState(false);

    const checkComplete = () => {
        let complete = true;

        if(!approvalProps.currency) complete=false;
        if(!approvalProps.require1099) complete=false;
        if(!approvalProps.foreignEntity) complete=false;
        if(!approvalProps.landedCost) complete=false;
        //if(!approvalProps.isTaxAgency) complete=false;
        if(!approvalProps.isLaborUnion) complete=false;

        return complete;
    };

    useEffect(() => {
        if(checkComplete()) setComplete(true);
        else setComplete(false);
    },[approvalProps.currency,approvalProps.require1099,approvalProps.foreignEntity,approvalProps.landedCost,approvalProps.isTaxAgency,approvalProps.isLaborUnion]);

    return(
        <div className='Vendor-Request-Confirmation'>
            <div className='Vendor-Request-Confirmation-Header'>
                {approveConfirmationTitle}
            </div>

            <div className='Vendor-Request-Confirmation-Body'>
                <div className='Vendor-Request-Confirmation-Line'>
                    <DropDownSelection title={'Currency'} options={['USD', 'CAN']} setTo={approvalProps.setCurrency} width={150} initializedValue={approvalProps.currency}/>
                    <DropDownSelection title={'Require 1099'} options={['Yes', 'No']} setTo={approvalProps.setRequire1099} width={150} initializedValue={approvalProps.require1099}/>
                    <DropDownSelection title={'Foreign Entity'} options={['Yes', 'No']} setTo={approvalProps.setForeignEntity} width={150} initializedValue={approvalProps.foreignEntity}/>
                    <DropDownSelection title={'Landed Cost'} options={['Yes', 'No']} setTo={approvalProps.setLandedCost} width={150} initializedValue={approvalProps.landedCost}/>
                    <DropDownSelection title={'Labor Union'} options={['Yes', 'No']} setTo={approvalProps.setIsLaborUnion} width={150} initializedValue={approvalProps.isLaborUnion}/>
                </div>
            </div>


            <div className='Vendor-Request-Confirmation-Button-Group'>
            {   complete &&
                <button onClick={() => confirm()} className='Vendor-Request-Confirmation-Button Vendor-Request-Confirm-Button'>Approve</button>
            }  
                <button onClick={() => deny()} className='Vendor-Request-Confirmation-Button Vendor-Request-Deny-Button'>Cancel</button>
            </div>
        


        </div>
    )
};

const VendorRecordHeader = ({vendorRecordHeaderObject}) => {
    const {closeVendorRecord,markVendorInactive,markVendorActive,vendorRecord, confirmSelection, openRejection,enterApproval, editRecord, setEditRecord, updateRecord} = vendorRecordHeaderObject;

    const inactiveConfirmationTitle = 'Are you sure you want to mark this vendor record as inactive?';
    const activeConfirmationTitle = 'Are you sure you want to mark this vendor record as active?';

    return(
        <div className='Vendor-Request-Record-Header'>
            {   vendorRecord.active && !editRecord &&
                <button onClick={() => enterApproval()} className='Vendor-Request-Button'>Approve</button>
            }
            {   vendorRecord.active && !editRecord &&
                <button onClick={() => openRejection()} className='Vendor-Request-Button'>Reject</button>
            }
            {   vendorRecord.active && !editRecord &&
                <button onClick={() => confirmSelection(inactiveConfirmationTitle,markVendorInactive)} className='Vendor-Request-Button'>Mark Inactive</button>
            }
            {   !vendorRecord.active && !editRecord &&
                <button onClick={() => confirmSelection(activeConfirmationTitle,markVendorActive)} className='Vendor-Request-Button'>Mark Active</button>
            }
            {   vendorRecord.active && !editRecord &&
                <button onClick={() => setEditRecord(true)} className='Vendor-Request-Button'>Edit</button>
            }
            {   vendorRecord.active && editRecord &&
                <button onClick={() => updateRecord(true)} className='Vendor-Request-Button'>Submit</button>
            }

            <button onClick={() => closeVendorRecord()} className='Vendor-Request-Button Vendor-Request-Button-End'>Exit</button>
        </div>
    )
};

const VendorRecordAddressField = ({title,addressObject,editting}) => {
    if(addressObject && !editting){
        return(
            <div className='Vendor-Request-Record-Form-Field'>
                <div className='Vendor-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Vendor-Request-Record-Form-Field-Value'>
                    {`${addressObject.street} ${addressObject.street2 ? addressObject.street2 : ''} ${addressObject.city}, ${addressObject.state} ${addressObject.zip} ${addressObject.country ? addressObject.country : ''}`}
                </div>
            </div>
        )
    }

    if(addressObject && editting){
        return(
            <div className='Vendor-Request-Record-Form-Field'>
                <div className='Vendor-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Vendor-Request-Record-Form-Field-Section'>
                    <div className='Vendor-Request-Record-Form-Under'>
                        <input style={{'width':'200px'}} value={addressObject.street} onChange={e => addressObject.setStreet(e.target.value)} className='Vendor-Request-Record-Form-Input'/>
                        <div className='Vendor-Request-Record-Form-Input-Title'>Street:</div>
                    </div>
                    <div className='Vendor-Request-Record-Form-Under'>
                        <input style={{'width':'120px'}} value={addressObject.street2} onChange={e => addressObject.setStreet2(e.target.value)} className='Vendor-Request-Record-Form-Input'/>
                        <div className='Vendor-Request-Record-Form-Input-Title'>Street2:</div>
                    </div>
                    <div className='Vendor-Request-Record-Form-Under'>
                        <input style={{'width':'150px'}} value={addressObject.city} onChange={e => addressObject.setCity(e.target.value)} className='Vendor-Request-Record-Form-Input'/>
                        <div className='Vendor-Request-Record-Form-Input-Title'>City:</div>
                    </div>
                    <div className='Vendor-Request-Record-Form-Under'>
                        <input style={{'width':'40px'}} value={addressObject.state} onChange={e => addressObject.setState(e.target.value)} className='Vendor-Request-Record-Form-Input'/>
                        <div className='Vendor-Request-Record-Form-Input-Title'>State:</div>
                    </div>
                    <div className='Vendor-Request-Record-Form-Under'>
                        <input style={{'width':'80px'}} value={addressObject.zip} onChange={e => addressObject.setZip(e.target.value)} className='Vendor-Request-Record-Form-Input'/>
                        <div className='Vendor-Request-Record-Form-Input-Title'>Zip:</div>
                    </div>
                    <div className='Vendor-Request-Record-Form-Under'>
                        <input style={{'width':'80px'}} value={addressObject.country} onChange={e => addressObject.setCountry(e.target.value)} className='Vendor-Request-Record-Form-Input'/>
                        <div className='Vendor-Request-Record-Form-Input-Title'>Country:</div>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return(
            <>
            </>
        )
    }

};

const VendorRecordField = ({title, value, editting=false, selections=[], setTo}) => {
    if(value){
        return(
            <div className='Vendor-Request-Record-Form-Field'>
                <div className='Vendor-Request-Record-Form-Field-Title'>
                    {title}
                </div>

                {   !editting &&
                <div className='Vendor-Request-Record-Form-Field-Value'>
                    {value}
                </div>
                }


                {   editting && selections.length < 1 &&
                <input className='Vendor-Request-Record-Form-Field-Input' value={value} onChange={e => setTo(e.target.value)}/>
                }

                {   editting && selections.length > 0 &&
                <select className='Vendor-Request-Dropdown'onChange={(e) => setTo(e.target.value)}>
                    <option value={value}>{value}</option>
                    {   selections.map((option, key) => (
                        <option className='Vendor-Request-Dropdown-Selection' key={key} value={option}>{option}</option>
                    ))
                    }
                </select>
                }

            </div>
        )   
    }
    else{
        return(
            <>
            </>
        )
    }

};

const VendorRecordListField = ({title,list}) => {
    if(list){
        let listString = '';

        list.forEach(value => {
            if(listString === '') listString = value
            else listString = listString + `, ${value}`;
        });
    
        return(
            <div className='Vendor-Request-Record-Form-Field'>
                <div className='Vendor-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Vendor-Request-Record-Form-Field-Value'>
                    {listString}
                </div>
            </div>
        )
    }
    else{
        return(
            <>
            </>
        )
    }

};

const VendorRecordContactField = ({title, contactObject}) => {
    if(contactObject){
        const contactString = `${contactObject.firstName} ${contactObject.lastName} - ${contactObject.number} ${contactObject.extension ? contactObject.extension : ''} - ${contactObject.email}`

        return(
            <div className='Vendor-Request-Record-Form-Field'>
                <div className='Vendor-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Vendor-Request-Record-Form-Field-Spacer'>
                </div>
                <div className='Vendor-Request-Record-Form-Field-Card'>
                    <div className='Vendor-Request-Record-Form-Field-Value'>
                        {`${contactObject.firstName} ${contactObject.lastName}`}
                    </div>
                    {   contactObject.title &&
                    <div className='Vendor-Request-Record-Form-Field-Value'>
                        {`${contactObject.title}`}
                    </div>
                    }
                    <div className='Vendor-Request-Record-Form-Field-Value'>
                        {`${contactObject.number}${contactObject.extension ? `: ${contactObject.extension}` : ''}`}
                    </div>
                    <div className='Vendor-Request-Record-Form-Field-Value'>
                        {`${contactObject.email}`}
                    </div>
                </div>

            </div>
        )
    }
    else{
        return(
            <>
            </>
        )
    }

};

const VendorRecordForm = ({vendorFormObject,vendorRecord,editting}) => {
    if(vendorRecord){
        let primaryContactObject = null;
        if(vendorRecord.primaryContact) {
            primaryContactObject = {
                firstName: vendorRecord.primaryContact.firstName,
                lastName: vendorRecord.primaryContact.lastName,
                title: vendorRecord.primaryContact.title,
                number: vendorRecord.primaryContact.number,
                email: vendorRecord.primaryContact.email,
                extension: vendorRecord.primaryContact.extension,
            };
        };
        
        let secondaryContactObject = null
        if(vendorFormObject.vendorRecord.secondaryContact) {
            secondaryContactObject = {
                firstName: vendorRecord.secondaryContact.firstName,
                lastName: vendorRecord.secondaryContact.lastName,
                title: vendorRecord.secondaryContact.title,
                number: vendorRecord.secondaryContact.number,
                email: vendorRecord.secondaryContact.email,
                extension: vendorRecord.secondaryContact.extension,
            };
        };

        return(
        <div className='Vendor-Request-Record-Form'>
            <VendorRecordField title={'Date Recieved:'} value={organizeDate(vendorRecord.date)}/>
            <VendorRecordField title={'Form Type:'} value={vendorFormObject.formType} editting={false} selections={['New', 'Update']} setTo={vendorFormObject.setFormType}/>
            <VendorRecordField title={'Vendor Type:'} value={vendorFormObject.vendorType} editting={editting} selections={['Supplier', 'Subcontractor', 'Government', 'Attorney', 'Rent']} setTo={vendorFormObject.setVendorType}/>


            <div className='Vendor-Request-Record-Spacer'/>

            <VendorRecordField title={'Legal Name:'} value={vendorFormObject.legalName} editting={editting} setTo={vendorFormObject.setLegalName}/>
            <VendorRecordField title={'Business Name:'} value={vendorFormObject.businessName} editting={editting} setTo={vendorFormObject.setBusinessName}/>
            <VendorRecordField title={'EIN / SSN:'} value={vendorRecord.einSsn}/>
            <VendorRecordAddressField title={'Business Address:'} addressObject={vendorFormObject.businessAddressObject} editting={editting}/>
            <VendorRecordListField title={'Business Classifications:'} list={vendorFormObject.vendorRecord.classificationList}/>

            <div className='Vendor-Request-Record-Spacer'/>

            <VendorRecordContactField title={'Primary Contact:'} contactObject={primaryContactObject}/>
            {   secondaryContactObject &&
                <VendorRecordContactField title={'Secondary Contact:'} contactObject={secondaryContactObject}/>
            }

            <div className='Vendor-Request-Record-Spacer'/>

            <VendorRecordField title={'Requested Payment Terms:'} value={vendorRecord.paymentTerms}/> 
            <VendorRecordField title={'Requested Payment Method:'} value={vendorRecord.paymentMethod}/>
            

            <div className='Vendor-Request-Record-Spacer'/>

            {   vendorRecord.bankAccount &&
            <>
                <VendorRecordField title={'Bank Name:'} value={vendorRecord.bankAccount.name}/>
                <VendorRecordField title={'Name on Account:'} value={vendorRecord.bankAccount.accountName}/>
                <VendorRecordField title={'Routing Number:'} value={vendorRecord.bankAccount.routingNumber}/>
                <VendorRecordField title={'Account Number:'} value={vendorRecord.bankAccount.accountNumber}/>
                <VendorRecordField title={'Account Type:'} value={vendorRecord.bankAccount.accountType}/>
                {   vendorRecord.bankAccount.swift_code &&
                    <VendorRecordField title={'Swift Code:'} value={vendorRecord.bankAccount.swiftCode}/>
                }
                <VendorRecordAddressField title={'Bank Address:'} addressObject={vendorRecord.bankAccount.address}/>
                <VendorRecordContactField title={'Bank Contact:'} contactObject={vendorRecord.bankAccount.contact}/>
            </>
            } 

            <div className='Vendor-Request-Record-Spacer'/>
            
            <VendorRecordField title={'Signer\'s Name:'} value={vendorRecord.signerName}/>
            <VendorRecordField title={'Signer\'s Title:'} value={vendorRecord.signerTitle}/>
            <VendorRecordField title={'Signature:'} value={vendorRecord.signature}/>
        </div>
    )
    } else return(<></>)
};

const VendorRecordFile = ({fileName, selectionTitle, id, selectPdf}) => {
    return(
        <div className='Vendor-Request-Record-File' onClick={() => selectPdf(selectionTitle, id)}>
            <FaFilePdf className='Vendor-Request-Record-File-Image'/>
            <div className='Vendor-Request-Record-File-Name'>
                {fileName}
            </div>
        </div>
    )
};

const VendorRecordFiles = ({vendorRecord, selectPdf}) => {
    return(
        <div className='Vendor-Request-Record-Files'>
            <div className='Vendor-Request-Record-Files-Title'>
                Attached Files
            </div>
            <div className='Vendor-Request-Record-Files-List'>
                {   vendorRecord.coiPath &&
                    <VendorRecordFile fileName={vendorRecord.coiPath} selectionTitle={'Coi'} id={vendorRecord.coi} selectPdf={selectPdf}/>
                }
                {   vendorRecord.internationalWirePath &&
                    <VendorRecordFile fileName={vendorRecord.internationalWirePath} selectionTitle={'InternationalWire'} id={vendorRecord.internationalWire} selectPdf={selectPdf}/>
                }
                {   vendorRecord.voidedCheckPath &&
                    <VendorRecordFile fileName={vendorRecord.voidedCheckPath} selectionTitle={'VoidedCheck'} id={vendorRecord.voidedCheck} selectPdf={selectPdf}/>
                }
                {   vendorRecord.w9Path &&
                    <VendorRecordFile fileName={vendorRecord.w9Path}  selectionTitle={'W9'} id={vendorRecord.w9} selectPdf={selectPdf}/>
                }
            </div>
        </div>
    )
};

const VendorRecordPdfViewer = ({closePdf,selectedPdf}) => {
    return(
        <div className='Vendor-Request-Record-Pdf-Viewer-Window' onClick={() => closePdf()}>

            <div className='Vendor-Request-Record-Pdf-Viewer'>
                {   selectedPdf &&
                <object data={`data:application/pdf;base64,${selectedPdf}`} type="application/pdf" width="100%" height="100%">
                    <p>Failed to Load PDF</p>
                </object>
                }
            </div>
        </div>
    )
};

const RejectionSection = ({setTo, title}) => {
    return(
        <div className='Vendor-Request-Rejection-Section'>
            <input type='checkbox' className='Vendor-Request-Rejection-Section-Button' onClick={e => setTo(e.target.checked)}/>
            <div className='Vendor-Request-Rejection-Section-Title'>
                {title}
            </div>
        </div>
    )
};

const RejectionWindow = ({closeRejection, confirmReject,setRejectionText,setRejectionSections}) => {
    const [ formType, setFormType ] = useState(false);
    const [ vendorInformation, setVendorInformation ] = useState(false);
    const [ contactInformation, setContactInformation ] = useState(false);
    const [ paymentInformation, setPaymentInformation ] = useState(false);
    const [ achInformation, setAchInformation ] = useState(false);
    const [ voidedCheckInformation, setVoidedCheckInformation ] = useState(false);
    const [ coiInformation, setCoiInformation ] = useState(false);
    const [ w9Information, setW9Information ] = useState(false);
    const [ intlWireInformation, setIntlWireInformation ] = useState(false);


    useEffect(() => {
        let rejectionList = [];
        if(formType) rejectionList.push('formType');
        if(vendorInformation) rejectionList.push('vendorInformation');
        if(contactInformation) rejectionList.push('contacts');
        if(paymentInformation) rejectionList.push('payment');
        if(achInformation) rejectionList.push('ach');
        if(voidedCheckInformation) rejectionList.push('voidedCheck');
        if(coiInformation) rejectionList.push('coi');
        if(w9Information) rejectionList.push('w9');
        if(intlWireInformation) rejectionList.push('intlWire');

        setRejectionSections(rejectionList);

    },[formType,vendorInformation,contactInformation,paymentInformation,achInformation,voidedCheckInformation,coiInformation,w9Information,intlWireInformation]);

    return(
        <div className='Vendor-Request-Rejection-Window'>
            <div className='Vendor-Request-Confirmation-Header'>
            Please enter a reason for rejecting this vendor request.
            </div>

            <textarea className='Vendor-Request-Rejection-Text' cols="50" rows="10" onChange={e => setRejectionText(e.target.value)}/>

            <div className='Vendor-Request-Confirmation-Header'>
            Please select the sections you would like updated.
            </div>

            <div className='Vendor-Request-Rejection-Sections'>
            <RejectionSection title={'Form Type'} setTo={setFormType}/>
            <RejectionSection title={'Vendor Information'} setTo={setVendorInformation}/>
            <RejectionSection title={'Contacts'} setTo={setContactInformation}/>
            <RejectionSection title={'Payment Information'} setTo={setPaymentInformation}/>
            <RejectionSection title={'ACH Details'} setTo={setAchInformation}/>
            <RejectionSection title={'Voided Check'} setTo={setVoidedCheckInformation}/>
            <RejectionSection title={'COI'} setTo={setCoiInformation}/>
            <RejectionSection title={'W9'} setTo={setW9Information}/>
            <RejectionSection title={'International Wire Instructions'} setTo={setIntlWireInformation}/>
            </div>

            <div className='Vendor-Request-Confirmation-Button-Group'>
                <button onClick={() => confirmReject()} className='Vendor-Request-Confirmation-Button Vendor-Request-Confirm-Button'>Confirm Rejection</button>
                <button onClick={() => closeRejection()} className='Vendor-Request-Confirmation-Button Vendor-Request-Deny-Button'>Cancel</button>
            </div>
        </div>
    )
};

const VendorRecordWindow = ({vendorRecordObject,accessToken}) => {
    const {vendorRecord, closeVendorRecord,markVendorInactive,markVendorActive,approveVendorRecord, rejectVendorRecord} = vendorRecordObject
    const [ confirmWindow, setConfirmWindow ] = useState(false);
    const [ approvalWindow, setApprovalWindow] = useState(false);
    const [ confirmText, setConfirmText ] = useState('');
    const [ callAfterFunction, setCallAfterFunction ] = useState();
    const [ pdfViewer, setPdfViewer ] = useState(false);
    const [ selectedPdf, setSelectedPdf ] = useState();

    const [ rejecting, setRejecting ] = useState(false);
    const [ rejectionText, setRejectionText ] = useState('');
    const [ rejectionSections, setRejectionSections ] = useState([]);

    const [currency, setCurrency] = useState('');
    const [require1099, setRequire1099] = useState('');
    const [foreignEntity, setForeignEntity] = useState('');
    const [landedCost, setLandedCost] = useState('');
    const [isTaxAgency, setIsTaxAgency] = useState('');
    const [isLaborUnion, setIsLaborUnion] = useState('');

    const [ editRecord, setEditRecord ] = useState(false);

    const [ formType, setFormType ] = useState('');
    const [ vendorType, setVendorType ] = useState('');
    const [ legalName, setLegalName ] = useState('');
    const [ businessName, setBusinessName ] = useState('');

    const [ bunsinessStreet,setBunsinessStreet ] = useState('');
    const [ bunsinessStreet2,setBunsinessStreet2 ] = useState('');
    const [ bunsinessCity,setBunsinessCity ] = useState('');
    const [ bunsinessState,setBunsinessState ] = useState('');
    const [ bunsinessZip,setBunsinessZip ] = useState('');
    const [ bunsinessCountry,setBunsinessCountry ] = useState('');

    const approvalProps = {
        currency: currency,
        setCurrency: setCurrency,
        require1099: require1099,
        setRequire1099: setRequire1099,
        foreignEntity: foreignEntity,
        setForeignEntity: setForeignEntity,
        landedCost: landedCost,
        setLandedCost: setLandedCost,
        isTaxAgency: isTaxAgency,
        setIsTaxAgency: setIsTaxAgency,
        isLaborUnion: isLaborUnion,
        setIsLaborUnion: setIsLaborUnion,
    };

    useEffect(() => {
        if(vendorRecord){
            setFormType(vendorRecord.formType);
            setVendorType(vendorRecord.vendorType);
            setLegalName(vendorRecord.legalName);
            setBusinessName(vendorRecord.businessName);
            
            if(vendorRecord.businessAddress) {
                setBunsinessStreet(vendorRecord.businessAddress.street);
                setBunsinessStreet2(vendorRecord.businessAddress.street2);
                setBunsinessCity(vendorRecord.businessAddress.city);
                setBunsinessState(vendorRecord.businessAddress.state);
                setBunsinessZip(vendorRecord.businessAddress.zip);
                if(vendorRecord.businessAddress.country)setBunsinessCountry(vendorRecord.businessAddress.country);
            };
        };
    },[vendorRecord]);

    const createVendorRecordObject = () => {
        const updatedRecord = {
            id: vendorRecord.id,
            formType: formType,
            vendorType: vendorType,
            legalName:legalName,
            businessName:businessName,
            addressId:vendorRecord.businessAddress.id,
            street:bunsinessStreet,
            city:bunsinessCity,
            state:bunsinessState,
            zip:bunsinessZip,
            country:bunsinessCountry,
        };
        return updatedRecord;
    };

    const updateRecord = async () => {
        const updatedRecord = createVendorRecordObject();
        const updatedRecordId = await newVendorRequestService.editRecord(accessToken,updatedRecord);
        if(updatedRecordId) setEditRecord(false);        
    };

    const getW9 = async (id) => {
        const w9 = await newVendorRequestService.getW9(accessToken,id);
        setSelectedPdf(w9);
    };

    const getCoi = async (id) => {
        const coi = await newVendorRequestService.getCoi(accessToken,id);
        setSelectedPdf(coi);
    };

    const getVoidedCheck = async (id) => {
        const coi = await newVendorRequestService.getVoidedCheck(accessToken,id);
        setSelectedPdf(coi);
    };

    const getInternationalWire = async (id) => {
        const internationalWire = await newVendorRequestService.getInternationalWire(accessToken,id);
        setSelectedPdf(internationalWire);
    };

    const closePdf = () => {
        setPdfViewer(false);
        setSelectedPdf(null);
    };

    const selectPdf = (selection, id) => {
        setPdfViewer(true);
        if(selection === 'Coi') getCoi(id);
        if(selection === 'W9') getW9(id);
        if(selection == 'VoidedCheck') getVoidedCheck(id);
        if(selection == 'InternationalWire') getInternationalWire(id);
    };

    const denySelection = () => {
        setConfirmText('');
        setConfirmWindow(false);
    };

    const approveVendor = () => {
        approveVendorRecord(currency,require1099,foreignEntity,landedCost,isTaxAgency,isLaborUnion);
    };

    const approveSelection = () => {
        setConfirmText('');
        setConfirmWindow(false);
        callAfterFunction();
    };

    const closeRejection = () => {
        setRejecting(false);
    };

    const confirmReject = () => {
        rejectVendorRecord(rejectionText,rejectionSections,closeRejection);
    };

    const openRejection = () => {
        setRejecting(true);
    };

    const exitApproval = () => {
        setApprovalWindow(false);
    };

    const enterApproval = () => {
        setApprovalWindow(true);
    };

    const confirmSelection = (text,confirmFunction) => {
        setConfirmText(text);
        setConfirmWindow(true);
        setCallAfterFunction(() => confirmFunction);
    };

    const vendorRecordHeaderObject = {
        closeVendorRecord:closeVendorRecord,
        markVendorInactive:markVendorInactive,
        markVendorActive:markVendorActive,
        vendorRecord:vendorRecord,
        confirmSelection:confirmSelection,
        approveVendorRecord:approveVendorRecord,
        openRejection:openRejection,
        editRecord:editRecord,
        setEditRecord:setEditRecord,
        updateRecord:updateRecord,
        enterApproval:enterApproval,
    };
    
    const businessAddressObject = {
        street:bunsinessStreet,
        setStreet: setBunsinessStreet,
        street2:bunsinessStreet2,
        setStreet2: setBunsinessStreet2,
        city:bunsinessCity,
        setCity: setBunsinessCity,
        state:bunsinessState,
        setState: setBunsinessState,
        zip:bunsinessZip,
        setZip: setBunsinessZip,
        country:bunsinessCountry,
        setCountry: setBunsinessCountry,
    };

    const vendorFormObject = {
        vendorRecord:vendorRecord,
        editRecord:editRecord,
        formType:formType,
        setFormType:setFormType,
        vendorType:vendorType,
        setVendorType:setVendorType,
        legalName:legalName,
        setLegalName:setLegalName,
        businessName:businessName,
        setBusinessName:setBusinessName,
        businessAddressObject:businessAddressObject,
    };

    return(
        <div className='Vendor-Request-Record-Window'>
            {   rejecting &&
                <RejectionWindow closeRejection={closeRejection} confirmReject={confirmReject} setRejectionText={setRejectionText} setRejectionSections={setRejectionSections}/>
            }
            {   !confirmWindow && !approvalWindow && !rejecting &&
            <>
                <VendorRecordHeader vendorRecordHeaderObject={vendorRecordHeaderObject}/>
                {   vendorRecord &&
                <>
                    <VendorRecordForm vendorFormObject={vendorFormObject} vendorRecord={vendorRecord} editting={editRecord}/>
                    <VendorRecordFiles vendorRecord={vendorRecord} selectPdf={selectPdf}/>
                </>
                }
                
            </>
            }
            {   approvalWindow &&
                <ApprovalVendorWindow title={confirmText} confirm={approveVendor} deny={exitApproval} approvalProps={approvalProps}/>
            }
            {   confirmWindow && 
                <ConfirmationWindow title={confirmText} confirm={approveSelection} deny={denySelection}/>
            }
            {   pdfViewer &&
                <VendorRecordPdfViewer closePdf={closePdf} selectedPdf={selectedPdf}/>
            }
        </div>
    )
};

const Loader = () => {
  return (
    <RotatingLines
      strokeColor="rgb(6, 89, 79)"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  )
};

const ApprovalWindow = ({approvalFinished,closeWindow}) => {
    const [ closing, setClosing ] = useState(false);

    useEffect(() => {
        if(approvalFinished){
            setClosing(true);

            setTimeout(() => {
                closeWindow();
                setClosing(false);
            },1000)};

    },[approvalFinished]);

    return(
        <div className='Vendor-Request-Approval'>
            {   !closing &&
            <>
            <Loader />
            <div className='Vendor-Request-Approval-Text'>
                Please Wait... Approving Vendor.
            </div>
            </>
            }
            {   closing &&
            <>
            <FaCheck className='Vendor-Request-Approval-Symbol' />
            <div className='Vendor-Request-Approval-Text'>
                Approved Vendor Record
            </div>
            </>

            }
        </div>
    )
};

const VendorRequests = () => {
    const contextObject = useContext(Context);

    const [recordId, setRecordId] = useState();

    const [ orderBy, setOrderBy ] = useState('date');

    const [ sortDirection, setSortDirection ] = useState('ASC');
    const [ activeSort, setActiveSort ] = useState(true);

    const [ vendorList, setVendorList ] = useState([]);
    const [ vendorRecord, setVendorRecord ] = useState({});
    const [ searchInput, setSearchInput ] = useState('');
    const [ searchList, setSearchList] = useState([]);

    const [ approving, setApproving ] = useState(false);
    const [ approvalFinished, setApprovalFinished ] = useState(false);


    const approveVendorRecord = async (currency,require1099,foreignEntity,landedCost,isTaxAgency,isLaborUnion) => {
        setApprovalFinished(false);
        setApproving(true);
        const approvalObject = {
            vendorId: recordId,
            currency:currency,
            require1099:require1099,
            foreignEntity:foreignEntity,
            landedCost:landedCost,
            isTaxAgency:isTaxAgency,
            isLaborUnion:isLaborUnion,
        };
        const approval = await newVendorRequestService.approveRecord(contextObject.apiToken, approvalObject);
        console.log(approval.status)
        const boxFolderId = approval.data.box;
        const acumaticaId = approval.data.acumatica;
        if(approval.status > 199 && approval.status < 300){
            setApprovalFinished(true);
            window.open(`https://cornerstonedetentionproducts.app.box.com/folder/${boxFolderId}`)
            window.open(`https://cornerstoneinc.acumatica.com/Main?CompanyID=Pilot&ScreenId=AP303000&AcctCD=${acumaticaId}`)
        }
        else{
            alert('APPROVAL FAILURE');
        };
    };

    const closeWindow = () => {
        console.log('Close Window')
        setRecordId(null);
        setApproving(false);
        setApprovalFinished(false);
        refreshVendorList();
        setSearchInput('');
    };

    const rejectVendorRecord = ( rejectionText, rejectionSections, closeRejection) => {
        const rejection = {
            vendorId:vendorRecord.id,
            text:rejectionText,
            sections:rejectionSections,
        };

        newVendorRequestService.submitRejectionRecord(contextObject.apiToken, rejection).then(res => closeRejection());
    };

    const markVendorInactive = () => {
        newVendorRequestService.inactivateRecord(contextObject.apiToken, recordId).then(res => {closeVendorRecord(); refreshVendorList()});
    };

    const markVendorActive = () => {
        newVendorRequestService.activateRecord(contextObject.apiToken, recordId).then(res => {closeVendorRecord(); refreshVendorList()});
    };

    const closeVendorRecord = () => {
        setRecordId(null);
    };

    const resetList = () => {
        setSearchInput('');
        setOrderBy('date');
        setActiveSort(true);
        refreshVendorList();
        setSortDirection('ASC');
    };

    const selectSearchItem = (businessName) => {
        const filterObject = {
            searchInput:businessName,
        };
        newVendorRequestService.getVendorList(contextObject.apiToken, filterObject).then(res => setVendorList(res.data.vendorList));
    };

    const refreshSearchList = async () => {
        if(searchInput){
            const searchObject = {
                searchInput: searchInput,
            };
            newVendorRequestService.getSearchList(contextObject.apiToken, searchObject).then(res => setSearchList(res.data.searchList));
        }
    };

    useEffect(() => {
        refreshSearchList();
    },[searchInput]);

    const selectVendorRecord = (id) => {
        setRecordId(id);
        newVendorRequestService.getVendorRecord(contextObject.apiToken, id).then(res => setVendorRecord(res.data.vendorRecord));
    };

    const flipSortOrder = () => {
        if(sortDirection === 'ASC') setSortDirection('DESC');
        else setSortDirection('ASC');
    };

    const handleOrderClick = (newOrderBy) => {
        if(newOrderBy === orderBy) flipSortOrder();
        else setOrderBy(newOrderBy)
    };

    const toggleFlag = (id) => {
        newVendorRequestService.toggleVendorFlag(contextObject.apiToken, id).then(res => refreshVendorList());
    };

    const refreshVendorList = () => {
        const filterObject = {
            active: activeSort,
            sortDirection: sortDirection,
            orderBy:orderBy,
            searchInput:searchInput,
        };
        newVendorRequestService.getVendorList(contextObject.apiToken, filterObject).then(res => setVendorList(res.data.vendorList));
    };

    useEffect(() => {
        refreshVendorList();
    },[sortDirection, orderBy, activeSort]);

    const vendorHeaderObject = {
        setSearchInput:setSearchInput,
        sortDirection:sortDirection,
        flipSortOrder:flipSortOrder,
        screenWidth:contextObject.screenWidth,
        handleOrderClick:handleOrderClick,
        orderBy: orderBy,
        searchList:searchList,
        selectSearchItem:selectSearchItem,
        resetList:resetList,
        activeSort:activeSort,
        setActiveSort:setActiveSort,
    };

    const vendorRequestObject = {
        vendorList:vendorList,
        selectVendorRecord:selectVendorRecord,
        toggleFlag:toggleFlag,
        screenWidth: contextObject.screenWidth,
    };

    const vendorRecordObject = {
        vendorRecord:vendorRecord,
        closeVendorRecord:closeVendorRecord,
        markVendorInactive:markVendorInactive,
        markVendorActive:markVendorActive,
        approveVendorRecord:approveVendorRecord,
        contextObject: contextObject,
        rejectVendorRecord:rejectVendorRecord,
    };

    return(
        <div className='Vendor-Request-Window'>
            {   !recordId && !approving &&
            <>
                <VendorRequestHeader vendorHeaderObject={vendorHeaderObject}/>
                <VendorRequestDisplay vendorRequestObject={vendorRequestObject}/>      
            </>
            }
            {   recordId && !approving &&
                <VendorRecordWindow accessToken={contextObject.apiToken} vendorRecordObject={vendorRecordObject}/>
            }
            {   approving &&
                <ApprovalWindow approvalFinished={approvalFinished} closeWindow={closeWindow}/>
            }

        </div>
    )
};

export default VendorRequests;