import './index.css';
import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import App from './App';
import NewVendor from './NewVendor/NewVendor';
import SetPassword from './SetPassword/SetPassword';
import NewCustomer from './Customers/NewCustomer';
import NoPage from './NoPage/NoPage'

// 




export default function PrimaryRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<App />} />
          <Route path="newVendor" element={<NewVendor />} />
          <Route path="newCustomer" element={<NewCustomer />} />

          <Route path="setPassword" element={<SetPassword />} />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<PrimaryRoute />);
