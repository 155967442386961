import { useEffect, useState,useContext } from 'react';
import './ProjectBookingReview.css';
import {Context} from '../Context';
import newVendorRequestService from '../Services/VendorRequestService';
import moment from 'moment';
import { FaFlag, FaHammer,FaSearch,FaArrowUp,FaArrowDown,FaFilePdf,FaCheck,FaBackward } from "react-icons/fa";
import { MdHomeRepairService } from "react-icons/md";


import { RotatingLines } from "react-loader-spinner";
import newProjectBookingDataService from '../Services/ProjectBookingService';

const customerBaseRoute = 'https://cornerstoneinc.acumatica.com/(W(1))/Main?CompanyID=Pilot&ScreenId=AR303000&AcctCD=';
const proestBaseRoute = 'https://cloud.proest.com/projects/';

const standardBudgetUploadInstructions = 'https://cornerstonedetentionproducts.app.box.com/file/1747490790907';
const proestBudgetUploadInstructions = 'https://cornerstonedetentionproducts.app.box.com/file/1747492458240';

const organizeDate = (date) => {
    return moment(date).format("MMM Do YYYY");
};

const organizeInputDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

const branchOptions = [
    {text:'02 - DEC Construction', value:'02'},
    {text:'04 - Electronics', value:'04'},
    {text:'06 - C3 Mattresses', value:'06'},
    {text:'07 - Airteq Locks', value:'07'},
    {text:'09 - Modular Construction', value:'09'},
    {text:'12 - Service North', value:'12'},
    {text:'61 - Service East', value:'61'},
    {text:'62 - Service Southwest', value:'62'},
    {text:'64 - Service Southeast', value:'64'},
    {text:'66 - Service South', value:'66'},
];

const trueOrFalseOptions = [
    {text: 'Yes', value: true},
    {text: 'No', value: false},
];

const insuranceProgramOptions = [
    {text: 'Contractor Controlled Insurance Program', value: 'CCIP'},
    {text: 'Owner Controlled Insurance Program', value: 'OCIP'},
    {text: 'None', value: null}
];

const taxabilityOptions = [
    {text:'Customer Taxed', value:'Customer'},
    {text:'Contractor Taxed',value:'Contractor'},
    {text:'Exempt',value:'Exempt'}
];

const requestTypeOptions = [
    {text:'Project', value:'Project'},
    {text:'Service Contract',value:'Service'}
];

const billingOptions = [
    {text:'Complete At Start', value:'upfront'},
    {text:'On Visits', value:'onVisit'},
    {text:'Monthly', value:'monthly'},
    {text:'Quarterly', value:'quarterly'}
];


const getFilteredProjectManagers = async (apiToken,branch,setProjectManagerList,setProjectManagerError) => {
    newProjectBookingDataService.getFilteredProjectManagerList(apiToken,branch)
    .then(response =>{
        if(response.data.length > 0){
            let projectManagerOptions = [];
            response.data.forEach(element => {
                const tempPM = {text: `${element.id} - ${element.name} - ${element.email}`, value: element.id}
                projectManagerOptions.push(tempPM);
            });
            setProjectManagerList(projectManagerOptions);
            return projectManagerOptions;
        }
        else{
            setProjectManagerList([{text:'No Project Managers Listed', value:null},]);
            return null;
        }

    }).catch( e=> {
        setProjectManagerError(e.toString());
    });
};

const getServiceContracts = async (apiToken,projectId,setServiceContracts,setServiceContractsError) => {
    newProjectBookingDataService.getServiceContracts(apiToken,projectId)
    .then(response =>{
        if(response.data.serviceContracts.length > 0){
            setServiceContracts(response.data.serviceContracts);
            return response.data.serviceContracts;
        }
        else{
            return null;
        }

    }).catch( e=> {
        setServiceContractsError(e.toString());
    });
};

const openProestBudgetLink = (estimateNumber) => {
    const newWindow = window.open(proestBaseRoute+estimateNumber+'/summary', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

const openProjectBookingTask = async (token, fileName) => {
    newProjectBookingDataService.getBudgetFile(token,fileName);
};

const openProjectBookingRevenue = async (token, fileName) => {
    newProjectBookingDataService.getBudgetFile(token,fileName);
};

const openProjectBookingCost = async (token, fileName) => {
    newProjectBookingDataService.getBudgetFile(token,fileName);
};

const DropDownSelection = ({title, options, setTo, width, initializedValue}) => {
    const inputCssProps = {
        '--inputWidth': `${width}`
    };

    const selectOption = (value) => {
         setTo(value);
    };

    return(
        <div className='External-Form-Input' style={inputCssProps}>
        <div className='External-Form-Input-Title'>
            {title}
        </div>

        <select className='External-Form-Dropdown' onChange={(e) => selectOption(e.target.value)}>
            <option value={true}>{initializedValue}</option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        
    </div>
    )
};

const SearchBar = ({setSearchInput,searchList, selectSearchItem}) => {
    const [ focused, setFocused ] = useState(false);

    const handleSearchItemClick = (businessName) => {
        selectSearchItem(businessName);
        setFocused(false);
        document.getElementById('myInput').value = ''
        setSearchInput('');
    };

    return(      
        <div className='Header-Search' onClick={() => setFocused(true)}  onMouseLeave={() => setFocused(false)}>
            <FaSearch  className='Header-Search-Icon'/>
            <input autoComplete="off"  onChange={((e) => setSearchInput(e.target.value))} id="myInput" className='Header-Search-Text' placeholder='Search Project Requests'/>

            {   focused && searchList && 
            <div className='Header-Search-List'>
            {   
                searchList.map((object, key) => (
                    <div key={key} className='Header-Search-Term' onClick={(event) => {handleSearchItemClick(object.business_name); event.stopPropagation()}}>
                        {object.business_name}
                    </div>
                ))
            }
            </div>
            }

        </div>
    )
};

const ActiveSelector = ({activeSort,setActiveSort}) => {
    return(
        <div className='Header-Active'>

            {   activeSort && activeSort !== 'both' &&
            <div className='Header-Select Selected-Active'>
                Active
            </div>
            }
            { activeSort === 'both' &&
            <div className='Header-Select ' onClick={() => setActiveSort(true)}>
                Active
            </div>
            }
            {   !activeSort &&
            <div className='Header-Select ' onClick={() => setActiveSort(true)}>
                Active
            </div>
            }


            <div className='Header-Select-Divider'/>

            {   !activeSort &&
            <div className='Header-Select Selected-Active'>
                InActive
            </div>
            }
            {   activeSort &&
            <div className='Header-Select' onClick={() => setActiveSort(false)}>
                InActive
            </div>  
            }

            <div className='Header-Select-Divider'/>

            {   activeSort === 'both' &&
            <div className='Header-Select Selected-Active'>
                Both
            </div>
            }
            {   activeSort !== 'both' &&
            <div className='Header-Select' onClick={() => setActiveSort('both')}>
                Both
            </div>  
            }
        </div>
    )
};

const ControlBar = ({resetList,activeSort,setActiveSort}) => {
    return(
        <div className='Header-Controls'>
            <ActiveSelector activeSort={activeSort} setActiveSort={setActiveSort}/>
            <button onClick={() => resetList()} className='Header-All-Button'>Default View</button>
        </div>
    )
};

const SortDirectionIndicator = ({currentSelection, validSelection, direction}) => {
    if(currentSelection === validSelection){
        if(direction === 'ASC') return <FaArrowUp />
        else return <FaArrowDown />
    }

};

const BottomHeader = ({screenWidth, sortDirection, orderBy, handleOrderClick}) => {
    return(
        <div className='Bottom-Header'>

            <div className='Tile-Information'>
                <div className='Tile-Data Header-Title' style={{'width':'150px'}} onClick={() => handleOrderClick('project_type')}>
                    Request Type <SortDirectionIndicator currentSelection={orderBy} validSelection={'project_type'} direction={sortDirection}/>
                </div>
                <div className='Tile-Data Header-Title' style={{'width':'250px'}} onClick={() => handleOrderClick('a.name')}>
                    Customer Name <SortDirectionIndicator currentSelection={orderBy} validSelection={'a.name'} direction={sortDirection}/>
                </div>
                <div className='Tile-Data Header-Title' style={{'width':'250px'}} onClick={() => handleOrderClick('c.name')}>
                    Project Name <SortDirectionIndicator currentSelection={orderBy} validSelection={'c.name'} direction={sortDirection}/>
                </div>
                <div className='Tile-Data Header-Title' style={{'width':'100px'}} onClick={() => handleOrderClick('branch')}>
                    Branch <SortDirectionIndicator currentSelection={orderBy} validSelection={'branch'} direction={sortDirection}/>
                </div>
                <div className='Tile-Data Header-Title'style={{'width':'180px'}} onClick={() => handleOrderClick('city')}>
                    Project Location <SortDirectionIndicator currentSelection={orderBy} validSelection={'city'} direction={sortDirection}/>
                </div>
                <div className='Date Header-Title'style={{'width':'150px'}} onClick={() => handleOrderClick('date')}>
                    Date Received <SortDirectionIndicator currentSelection={orderBy} validSelection={'date'} direction={sortDirection}/>
                </div>
            </div>

        </div>
    )
};

const RequestHeader = ({headerObject}) => {
    const {setSearchInput,sortDirection,flipSortOrder,screenWidth, orderBy, handleOrderClick, searchList, selectSearchItem,resetList, 
        activeSort, setActiveSort} = headerObject;

    return(
        <div className='Header'>
            <div className='Top-Header'>
                <SearchBar setSearchInput={setSearchInput} searchList={searchList} selectSearchItem={selectSearchItem}/>
                <ControlBar resetList={resetList} activeSort={activeSort} setActiveSort={setActiveSort}/>
            </div>
            <BottomHeader screenWidth={screenWidth} sortDirection={sortDirection} orderBy={orderBy} handleOrderClick={handleOrderClick} />
        </div>
    )
};

const TileHoverPanel = ({request,toggleFlag}) => {
    return (
        <div className='Flag-Frame'>
            {   request.flag &&
                <FaFlag className='Flag-Checked' onClick={(event) => {toggleFlag(request.id); event.stopPropagation()}}/>
            }
            {   !request.flag &&
                <FaFlag className='Flag-Unchecked' onClick={(event) => {toggleFlag(request.id); event.stopPropagation()}}/>
            }
            
        </div>
    )
};

const Tile = ({request, selectRecord, toggleFlag, screenWidth}) => {
    const [ hover, setHover ] = useState();

    return(
        <div className='Vender-Request-Tile' onClick={() => selectRecord(request.id)} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            { request.projectType === 'Project' &&
                <FaHammer className='Tile-Image'/>
            }
            { request.projectType === 'Service' &&
                <MdHomeRepairService className='Tile-Image'/>
            }


            <div className='Tile-Information'>
                <div className='Tile-Data' style={{'width':'150px'}}>
                    {`${request.projectType} Request`}
                </div>

                <div className='Tile-Data' style={{'width':'250px'}}>
                    {request.customerName}
                </div>

                <div className='Tile-Data' style={{'width':'250px'}}>
                    {request.name}
                </div>


                <div className='Tile-Data' style={{'width':'100px'}}>
                    {request.branch}
                </div>

                <div className='Tile-Data' style={{'width':'180px'}}>
                    {`${request.city}, ${request.state}`}
                </div>

                <div className='Tile-Data' style={{'width':'150px'}}>
                    {organizeDate(request.date)}
                </div>
                {   screenWidth > 1000 &&
                <>
                {hover && <TileHoverPanel request={request} toggleFlag={toggleFlag}/>}
                {!hover &&
                    <div className='Flag-Frame'>
                        {   request.flag &&
                            <FaFlag className='Flag'/>
                        }
                    </div>
                }
                </>
                }
 
            </div>
            
        </div>
    )
};

const EmptyDisplay = () => {
    return(
        <div className='No-Display'>
            No Records to Display.
        </div>
    )
};

const RequestDisplay = ({listObject}) => {
    const { requestList, selectRecord, toggleFlag, screenWidth } = listObject;
    return(
        <div className='Display'> 
            {   requestList &&
                requestList.map((request, key) => (
                    <Tile key={key} request={request} selectRecord={selectRecord} toggleFlag={toggleFlag} screenWidth={screenWidth}/>
                ))
            }
            {   !requestList &&
                <EmptyDisplay />
            }
        </div>
    )
};

const ConfirmationWindow = ({title, confirm, deny}) => {
    return(
        <div className='Confirmation'>
            <div className='Confirmation-Header'>
                {title}
            </div>
            <div className='Confirmation-Button-Group'>
            <button onClick={() => confirm()} className='Confirmation-Button Confirm-Button'>Yes</button>
            <button onClick={() => deny()} className='Confirmation-Button Deny-Button'>No</button>
            </div>

        </div>
    )
};

const FinalApproveWindow = ({confirm, deny, approvalProps}) => {
    const approveConfirmationTitle = 'Please complete the form below to approve this vendor.';
    const [complete,setComplete] = useState(false);

    const checkComplete = () => {
        let complete = true;

        if(!approvalProps.currency) complete=false;

        return complete;
    };

    useEffect(() => {
        if(checkComplete()) setComplete(true);
        else setComplete(false);
    },[approvalProps.currency,]);

    return(
        <div className='Confirmation'>
            <div className='Confirmation-Header'>
                {approveConfirmationTitle}
            </div>

            <div className='Confirmation-Body'>
                <div className='Confirmation-Line'>
                    <DropDownSelection title={'Currency'} options={['USD', 'CAN']} setTo={approvalProps.setCurrency} width={150} initializedValue={approvalProps.currency}/>

                </div>
            </div>


            <div className='Confirmation-Button-Group'>
            {   complete &&
                <button onClick={() => confirm()} className='Confirmation-Button Confirm-Button'>Approve</button>
            }  
                <button onClick={() => deny()} className='Confirmation-Button Deny-Button'>Cancel</button>
            </div>
        


        </div>
    )
};

const RecordHeader = ({headerObject}) => {
    const {closeRecord,markInactive,markActive,requestRecord, confirmSelection, openRejection,enterApproval, editRecord, setEditRecord, 
        updateRecord, continueApproving ,projectCreateComplete, approvalFinished} = headerObject;

    const inactiveConfirmationTitle = 'Are you sure you want to mark this project request as inactive?';
    const activeConfirmationTitle = 'Are you sure you want to mark this project request as active?';

    return(
        <div className='Record-Header'>
            {   requestRecord && requestRecord.active && !editRecord && !projectCreateComplete && !approvalFinished &&
                <button onClick={() => enterApproval()} className='Button'>Approve</button>
            }
            {   requestRecord && requestRecord.active && !editRecord && !projectCreateComplete && !approvalFinished &&
                <button onClick={() => openRejection()} className='Button'>Reject</button>
            }
            {   requestRecord && projectCreateComplete && !approvalFinished &&
                <button onClick={() => continueApproving()} className='Button'>Continue Approving</button>
            }
            {   requestRecord && requestRecord.active && !editRecord && !approvalFinished &&
                <button onClick={() => confirmSelection(inactiveConfirmationTitle,markInactive)} className='Button'>Mark Inactive</button>
            }
            {   requestRecord && !requestRecord.active && !editRecord && !approvalFinished &&
                <button onClick={() => confirmSelection(activeConfirmationTitle,markActive)} className='Button'>Mark Active</button>
            }
            {   requestRecord && requestRecord.active && !editRecord && !projectCreateComplete && !approvalFinished &&
                <button onClick={() => setEditRecord(true)} className='Button'>Edit</button>
            }
            {   requestRecord && requestRecord.active && editRecord && !projectCreateComplete && !approvalFinished &&
                <button onClick={() => updateRecord(true)} className='Button'>Submit</button>
            }

            <button onClick={() => closeRecord()} className='Button Button-End'>Exit</button>
        </div>
    )
};

const AddressField = ({title,addressObject,editting}) => {
    if(addressObject && !editting){
        return(
            <div className='Record-Form-Field'>
                <div className='Record-Form-Field-Title'>
                    {title}:
                </div>
                <div className='Record-Form-Field-Value'>
                    {`${addressObject.street} ${addressObject.street2 ? addressObject.street2 : ''} ${addressObject.city}, ${addressObject.state} ${addressObject.zip} ${addressObject.country ? addressObject.country : ''}`}
                </div>
            </div>
        )
    }

    if(addressObject && editting){
        return(
            <div className='Record-Form-Field'>
                <div className='Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Record-Form-Field-Section'>
                    <div className='Record-Form-Under'>
                        <input style={{'width':'200px'}} value={addressObject.street} onChange={e => addressObject.setStreet(e.target.value)} className='Record-Form-Input'/>
                        <div className='Record-Form-Input-Title'>Street:</div>
                    </div>
                    <div className='Record-Form-Under'>
                        <input style={{'width':'120px'}} value={`${addressObject.street2 ? addressObject.street2 : ''}`} onChange={e => addressObject.setStreet2(e.target.value)} className='Record-Form-Input'/>
                        <div className='Record-Form-Input-Title'>Street2:</div>
                    </div>
                    <div className='Record-Form-Under'>
                        <input style={{'width':'150px'}} value={addressObject.city} onChange={e => addressObject.setCity(e.target.value)} className='Record-Form-Input'/>
                        <div className='Record-Form-Input-Title'>City:</div>
                    </div>
                    <div className='Record-Form-Under'>
                        <input style={{'width':'40px'}} value={addressObject.state} onChange={e => addressObject.setState(e.target.value)} className='Record-Form-Input'/>
                        <div className='Record-Form-Input-Title'>State:</div>
                    </div>
                    <div className='Record-Form-Under'>
                        <input style={{'width':'80px'}} value={addressObject.zip} onChange={e => addressObject.setZip(e.target.value)} className='Record-Form-Input'/>
                        <div className='Record-Form-Input-Title'>Zip:</div>
                    </div>
                    <div className='Record-Form-Under'>
                        <input style={{'width':'80px'}} value={addressObject.country} onChange={e => addressObject.setCountry(e.target.value)} className='Record-Form-Input'/>
                        <div className='Record-Form-Input-Title'>Country:</div>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return(
            <>
            </>
        )
    }

};

const RecordField = ({title, value, editting=false, selections=[], setTo, width=250}) => {
    return(
        <div className='Record-Form-Field'>
            <div className='Record-Form-Field-Title'>
                {title}:
            </div>

            {   !editting &&
            <div className='Record-Form-Field-Value'>
                {value}
            </div>
            }


            {   editting && selections.length < 1 &&
            <input className='Record-Form-Field-Input' style={{'width':`${width}px`}} value={value} onChange={e => setTo(e.target.value)}/>
            }

            {   editting && selections.length > 0 &&
            <select className='Dropdown'onChange={(e) => setTo(e.target.value)}>
                <option value={value}>{value}</option>
                {   selections.map((option, key) => (
                    <option className='Dropdown-Selection' key={key} value={option.value}>{option.text}</option>
                ))
                }
            </select>
            }

        </div>
    )
};

const TextAreaField = ({title, value, editting=false, setTo}) => {
    return(
        <div className='Record-Form-Field'>
            <div className='Record-Form-Field-Title'>
                {title}:
            </div>

            {   !editting &&
            <div className='Record-Form-Field-Value'>
                {value}
            </div>
            }


            {   editting && 
            <textarea className='Record-Form-Field-Input' style={{'width':`600px`,'height':`100px`,'resize':'none'}} value={value} onChange={e => setTo(e.target.value)}/>
            }
        </div>
    )
};

const DateInputField = ({title, value, setTo, editting, width=300}) => {
    return(
        <div className='Record-Form-Field'>
            <div className='Record-Form-Field-Title'>
                {title}:
            </div>
            {   !editting &&
                <div className='Record-Form-Field-Value'>
                    {organizeDate(value)}
                </div>
            }
            {   editting &&
                <input className='Record-Form-Field-Input' type='date' style={{width:`${width}px`}} value={organizeInputDate(value)} onChange={e => setTo(e.target.value)}/>
            }
        </div>
    )
};

const SelectInputField = ({title, value, setTo,editting, options}) => {
    const getTextByValue = (value) => {
        const option = billingOptions.find(option => option.value === value);
        return option ? option.text : null; // Returns the text or null if not found
    };

    return(
        <div className='Record-Form-Field'>          
            <div className='Record-Form-Field-Title'>
                {title}:
            </div>
            { !editting &&
                <div className='Record-Form-Field-Value'>
                    {getTextByValue(value)}
                </div>
            }
            {   editting &&
            <select onChange={e => setTo(e.target.value)} value={value}>
                <option value="" disabled hidden>Select an option</option>
                {   
                options.map((element, key) => (
                    <option key={key} value={element.value}>{element.text}</option>
                ))
                }
            </select>
            }
        </div>
    )
};

const RecordForm = ({formObject}) => {
    const {accessToken,requestRecord, editRecord,customer, setCustomer, customerLocation, setCustomerLocation, projectName, setProjectName,projectType,setProjectType, 
        entityName, setEntityName, poNumber, setPoNumber, projectAddressObject, bondRequired, setBondRequired, businessLicense, setBusinessLicense, 
        ocipCcip, setOcipCcip, ownerDirect, setOwnerDirect, permitsRequired, setPermitsRequired, prevailingWage, setPrevailingWage, contractAmount, setContractAmount, 
        costAmount, setCostAmount, margin, setMargin, retainage, setRetainage, taxStatus, setTaxStatus, notes, setNotes, branch, setBranch, 
        projectManagerString, projectManager, setProjectManager, availableProjectManagers, salesLink, setSalesLink, rejectedRecord, budgetType, estimateNumber, setEstimateNumber, estimateText,
        taskFile,setTaskFile,revenueFile,setRevenueFile,costFile,setCostFile,serviceContracts,updateServiceContract} = formObject;

    const openSalesLink = () => {
        const newWindow = window.open(salesLink, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const openCustomerLink = () => {
        const newWindow = window.open(customerBaseRoute+customer.customerId, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const ContractField = ({contract, id, editRecord, updateServiceContract}) => {
        const [amount,setAmount] = useState(contract.contractAmount);
        const [startDate,setStartDate] = useState(contract.startDate);
        const [endDate,setEndDate] = useState(contract.endDate);
        const [billingRule,setBillingRule] = useState(contract.billingRule);
        const [presoldHours,setPresoldHours] = useState(contract.presoldHours);
        const [materialAllowance,setMaterialAllowance] = useState(contract.materialAllowance);
        const [travelTime,setTravelTime] = useState(contract.travelTime);

        const sendUpdate = () => {
            const newContract = {
                id: contract.id,
                billingRule:billingRule,
                contractAmount:amount,
                contractYear:contract.contractYear,
                defaultRetainage:contract.defaultRetainage,
                endDate:endDate,
                margin:contract.margin,
                materialAllowance:materialAllowance,
                presoldHours:presoldHours,
                projectId:contract.projectId,
                startDate:startDate,
                travelTime:travelTime,
            }

            updateServiceContract(newContract,id,'Edit');
        };

        useEffect(() => {
            let changedRecord = false;

            if(amount !== contract.contractAmount) changedRecord = true;
            if(startDate !== contract.startDate) changedRecord = true;
            if(endDate !== contract.endDate) changedRecord = true;
            if(billingRule !== contract.billingRule) changedRecord = true;
            if(presoldHours !== contract.presoldHours) changedRecord = true;
            if(materialAllowance !== contract.materialAllowance) changedRecord = true;
            if(travelTime !== contract.traveltime) changedRecord = true;

            if(changedRecord) sendUpdate();
        },[amount,startDate,endDate,billingRule,presoldHours,materialAllowance,travelTime]);

        return(
            <div className='Record-Contract'>
                <RecordField title={'Contract Year'} value={contract.contractYear}/>
                <RecordField title={'Amount'} value={amount} editting={editRecord} setTo={setAmount}/>
                <DateInputField title={'Start'} value={startDate} editting={editRecord} setTo={setStartDate}/>
                <DateInputField title={'End'} value={endDate} editting={editRecord} setTo={setEndDate}/>
                <SelectInputField title={'Billing Rule'} value={billingRule} editting={editRecord} setTo={setBillingRule} options={billingOptions}/>
                <RecordField title={'Presold Hours'} value={presoldHours} editting={editRecord} setTo={setPresoldHours}/>
                <RecordField title={'Material Allowance'} value={materialAllowance} editting={editRecord} setTo={setMaterialAllowance}/>
                <RecordField title={'Travel Time'} value={travelTime} editting={editRecord} setTo={setTravelTime}/>
            </div>
        )
    };

    if(requestRecord){
        return(
        <div className='Record-Form'>
            <RecordField title={'Date Received'} value={organizeDate(requestRecord.date)}/>
            <RecordField title={'Submitted By'} value={requestRecord.submitter.name}/>

            {   rejectedRecord &&
                <RecordField title={'Rejected On'} value={organizeDate(rejectedRecord.date)}/>
            }
            {   rejectedRecord && rejectedRecord.resubmitDate &&
                <RecordField title={'Resubmitted On'} value={organizeDate(rejectedRecord.resubmitDate)}/>
            }


            {   requestRecord && requestRecord.bookedDate &&
            <>
                <div className='Record-Spacer'/>
                <RecordField title={'Project Booked On'} value={organizeDate(requestRecord.bookedDate)}/>
            </>
            }
            {   requestRecord && requestRecord.completeDate &&
                <RecordField title={'Project Booking Finalized On'} value={organizeDate(requestRecord.completeDate)}/>
            }
            {   requestRecord && requestRecord.bookedDate && !requestRecord.completeDate &&
                <RecordField title={'Project Booking Finalized On'} value={'* Project Booking Not Finalized *'}/>
            }
            {   requestRecord && requestRecord.acumaticaProjectId &&
            <>
                <RecordField title={'Acumatica Project ID'} value={requestRecord.acumaticaProjectId}/>
            </>
            }
            {   requestRecord && requestRecord.boxFolderId &&
                <RecordField title={'Box Folder ID'} value={requestRecord.boxFolderId}/>
            }



            <div className='Record-Spacer'/>

            <RecordField title={'Customer ID'} value={customer.customerId}/>
            <RecordField title={'Customer Name'} value={customer.name}/>
            <RecordField title={'Customer Class'} value={customer.class}/>
            <RecordField title={'Customer Location ID'} value={customerLocation.locationId}/>
            <RecordField title={'Customer Location Name'} value={customerLocation.name}/>
            <AddressField title={'Customer Location Address'} addressObject={customerLocation.address}/>
            {   !editRecord &&
                <button className='Record-Sales-Link' onClick={() => openCustomerLink()}>Customer Record Link</button>
            }

            {   editRecord &&
                <div>
                    Select New Customer Option
                </div>
            }

            <div className='Record-Spacer'/>

            <RecordField title={'Entity Name'} value={entityName} editting={editRecord} setTo={setEntityName}/>
            <RecordField title={'PO Number'} value={poNumber} editting={editRecord} setTo={setPoNumber}/>

            <div className='Record-Spacer'/>

            <RecordField title={'Branch'} value={branch} editting={editRecord} selections={branchOptions} setTo={setBranch}/>
            <RecordField title={'Project Manager'} value={projectManagerString} editting={editRecord} selections={availableProjectManagers} setTo={setProjectManager}/>

            <div className='Record-Spacer'/>

            <RecordField title={'Request Type'} value={projectType} editting={editRecord} selections={requestTypeOptions} setTo={setProjectType}/>
            <RecordField title={'Project Name'} value={projectName} editting={editRecord} setTo={setProjectName}/>
            <AddressField title={'Project Address'} addressObject={projectAddressObject} editting={editRecord}/>

            <div className='Record-Spacer'/>

            <RecordField title={'Bonding Required'} value={bondRequired === 'true'  ? 'Yes' : 'No'} editting={editRecord} selections={trueOrFalseOptions} setTo={setBondRequired}/>
            <RecordField title={'Business License Required'} value={businessLicense === 'true'  ? 'Yes' : 'No'} editting={editRecord} selections={trueOrFalseOptions} setTo={setBusinessLicense}/>
            {   projectType === 'Project' &&
            <>
            <RecordField title={'Owner Direct Purchase'} value={ownerDirect === 'true'  ? 'Yes' : 'No'} editting={editRecord} selections={trueOrFalseOptions} setTo={setOwnerDirect}/>
            <RecordField title={'Insurance Program'} value={ocipCcip} editting={editRecord} selections={insuranceProgramOptions} setTo={setOcipCcip}/>
            </>
            }
            <RecordField title={'Certified Payroll / Prevailing Wage'} value={prevailingWage  === 'true' ? 'Yes' : 'No'} editting={editRecord} selections={trueOrFalseOptions} setTo={setPrevailingWage}/>
            <RecordField title={'Permits Required'} value={permitsRequired === 'true'  ? 'Yes' : 'No'} editting={editRecord} selections={trueOrFalseOptions} setTo={setPermitsRequired}/>
            <RecordField title={'Tax Status'} value={taxStatus} editting={editRecord} selections={taxabilityOptions} setTo={setTaxStatus}/>

            <div className='Record-Spacer'/>

            {   projectType === 'Project' &&
            <>
                <RecordField title={'Total Contract Value'} value={contractAmount} editting={editRecord} setTo={setContractAmount}/>
                <RecordField title={'Estimated Costs'} value={costAmount} editting={editRecord} setTo={setCostAmount}/>
                <RecordField title={'Margin (Percentage)'} value={margin} setTo={setMargin}/>
                <RecordField title={'Retainage (Percentage)'} value={retainage} editting={editRecord} setTo={setRetainage}/>
            </>
            }
            {   projectType === 'Service' && serviceContracts && serviceContracts.length > 0 &&
                serviceContracts.map((contract, key) => (
                    <ContractField contract={contract} id={key} key={key} editRecord={editRecord} updateServiceContract={updateServiceContract}/>
                ))
            }


            <div className='Record-Spacer'/>
            {   editRecord &&
                <RecordField title={'Sales Link'} value={salesLink} editting={editRecord} setTo={setSalesLink} width={600}/>
            }
            {   !editRecord &&
                <button className='Record-Sales-Link' onClick={() => openSalesLink()}>Sales Folder Link</button>
            }

            <div className='Record-Spacer'/>
            
            {   budgetType &&
                <TextAreaField title={'Type of Budget'} value={budgetType}/>
            }
            {   budgetType === 'Standard' && 
            <>
            <div className='Record-Budget-Files'>
                <button className='Record-Sales-Link' onClick={() => openProjectBookingTask(accessToken,requestRecord.budget.taskFileName)}>Download Tasks</button>
                <button className='Record-Sales-Link' onClick={() => openProjectBookingRevenue(accessToken,requestRecord.budget.revenueFileName)}>Download Revenue Budget</button>
                <button className='Record-Sales-Link' onClick={() => openProjectBookingCost(accessToken,requestRecord.budget.costFileName)}>Download Cost Budget</button>
            </div>
            </>
            }
            {   budgetType === 'Standard' && editRecord &&
            <>
            <div className='Record-Budget-File-Uploads'>
                <div className='Record-Budget-File-Upload'>
                    <div className='Record-Budget-File-Title'>Update Task File</div>
                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setTaskFile(e.target.files)}className='Record-Budget-File-Input'/>
                </div>
            </div>

            <div className='Record-Budget-File-Uploads'>
                <div className='Record-Budget-File-Upload'>
                    <div className='Record-Budget-File-Title'>Update Revenue File</div>
                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setRevenueFile(e.target.files)}className='Record-Budget-File-Input'/>
                </div>
            </div>

            <div className='Record-Budget-File-Uploads'>
                <div className='Record-Budget-File-Upload'>
                    <div className='Record-Budget-File-Title'>Update Cost File</div>
                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setCostFile(e.target.files)}className='Record-Budget-File-Input'/>
                </div>
            </div>
            </>
            }
            {   budgetType === 'ProEst' && estimateNumber && !editRecord &&
                <button className='Record-Sales-Link' onClick={() => openProestBudgetLink(estimateNumber)}>ProEst Estimate Link</button>
            }
            {   budgetType === 'ProEst' && editRecord &&
                <RecordField title={'ProEst Estimate Number'} value={estimateNumber} editting={editRecord} setTo={setEstimateNumber}/>
            }
            {   budgetType === 'None' && estimateText &&
                <TextAreaField title={'Reason for skipping budget'} value={estimateText}/>
            }

            <div className='Record-Spacer'/>

            <TextAreaField title={'Notes'} value={notes} editting={editRecord} setTo={setNotes}/>

        </div>
    )
    } else return(<></>)
};

const RecordFile = ({fileName, selectionTitle, id, selectPdf}) => {
    return(
        <div className='Record-File' onClick={() => selectPdf(selectionTitle, id)}>
            <FaFilePdf className='Record-File-Image'/>
            <div className='Record-File-Name'>
                {fileName}
            </div>
        </div>
    )
};

const RecordFiles = ({requestRecord, selectPdf}) => {
    return(
        <div className='Record-Files'>
            <div className='Record-Files-Title'>
                Attached Files
            </div>
            <div className='Record-Files-List'>
                {   requestRecord.contractFile &&
                    <RecordFile fileName={requestRecord.contractFile} selectionTitle={'Contract'} id={requestRecord.contract} selectPdf={selectPdf}/>
                }

                {   requestRecord.ocipFile &&
                    <RecordFile fileName={requestRecord.ocipFile} selectionTitle={'Ocip'} id={requestRecord.ocip} selectPdf={selectPdf}/>
                }

                {   requestRecord.bondingFile &&
                    <RecordFile fileName={requestRecord.bondingFile} selectionTitle={'Bonding'} id={requestRecord.bonding} selectPdf={selectPdf}/>
                }

                {   requestRecord.rateSheetFile &&
                    <RecordFile fileName={requestRecord.rateSheetFile} selectionTitle={'Prevailing Wage'} id={requestRecord.rateSheet} selectPdf={selectPdf}/>
                }

                {   requestRecord.taxExemptionFile &&
                    <RecordFile fileName={requestRecord.taxExemptionFile} selectionTitle={'Tax Exemption'} id={requestRecord.taxExemption} selectPdf={selectPdf}/>
                }

            </div>
        </div>
    )
};

const RecordPdfViewer = ({closePdf,selectedPdf}) => {
    return(
        <div className='Record-Pdf-Viewer-Window' onClick={() => closePdf()}>

            <div className='Record-Pdf-Viewer'>
                {   selectedPdf &&
                <object data={`data:application/pdf;base64,${selectedPdf}`} type="application/pdf" width="100%" height="100%">
                    <p>Failed to Load PDF</p>
                </object>
                }
            </div>
        </div>
    )
};

const RejectionSection = ({setTo, title}) => {
    return(
        <div className='Rejection-Section'>
            <input type='checkbox' className='Rejection-Section-Button' onClick={e => setTo(e.target.checked)}/>
            <div className='Rejection-Section-Title'>
                {title}
            </div>
        </div>
    )
};

const RejectionWindow = ({closeRejection, confirmReject,setRejectionText}) => {
    return(
        <div className='Rejection-Window'>
            <div className='Confirmation-Header'>
            Please enter a reason for rejecting this request.
            </div>

            <textarea className='Rejection-Text' cols="50" rows="10" onChange={e => setRejectionText(e.target.value)}/>

            <div className='Confirmation-Button-Group'>
                <button onClick={() => confirmReject()} className='Confirmation-Button Confirm-Button'>Confirm Rejection</button>
                <button onClick={() => closeRejection()} className='Confirmation-Button Deny-Button'>Cancel</button>
            </div>
        </div>
    )
};

const RecordWindow = ({recordObject,accessToken}) => {
    const { requestRecord, closeRecord,markInactive,markActive,approveRecord, rejectRecord,rejectedRecord,serviceContracts,
        updateServiceContract,projectCreateComplete,continueApproving,approvalFinished} = recordObject
    const [ confirmWindow, setConfirmWindow ] = useState(false);
    const [ approvalWindow, setApprovalWindow] = useState(false);
    const [ confirmText, setConfirmText ] = useState('');
    const [ callAfterFunction, setCallAfterFunction ] = useState();
    const [ pdfViewer, setPdfViewer ] = useState(false);
    const [ selectedPdf, setSelectedPdf ] = useState();

    const [ rejecting, setRejecting ] = useState(false);
    const [ rejectionText, setRejectionText ] = useState('');
    const [ rejectionSections, setRejectionSections ] = useState([]);

    const [currency, setCurrency] = useState('');

    const [ editRecord, setEditRecord ] = useState(false);

    const [customer, setCustomer] = useState({});
    const [customerLocation, setCustomerLocation] = useState({});
    const [projectName, setProjectName] = useState('');
    const [projectType, setProjectType] = useState('');
    const [entityName, setEntityName] = useState('');
    const [poNumber, setPoNumber] = useState('');
    const [street, setStreet] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    const [bondRequired, setBondRequired] = useState(false);
    const [businessLicense, setBusinessLicense] = useState(false);
    const [ocipCcip, setOcipCcip] = useState('');
    const [ownerDirect, setOwnerDirect] = useState(false);
    const [prevailingWage, setPrevailingWage] = useState(false);
    const [permitsRequired, setPermitsRequired] = useState(false);

    const [contractAmount, setContractAmount] = useState(0);
    const [costAmount, setCostAmount] = useState(0);
    const [margin, setMargin] = useState(0);
    const [retainage, setRetainage] = useState(0);
    const [taxStatus, setTaxStatus] = useState('');

    const [serviceContractsUpdated, setServiceContractsUpdated] = useState(false);
    const [serviceContractsupdatedError, setServiceContractsupdatedError] = useState('');

    const [budgetType, setBudgetType] = useState('');
    const [estimateNumber, setEstimateNumber] = useState('');
    const [estimateText,setEstimateText] = useState('');

    const [taskFile, setTaskFile] = useState();
    const [taskFileId, setTaskFileId] = useState();
    const [revenueFile, setRevenueFile] = useState();
    const [revenueFileId, setRevenueFileId] = useState();
    const [costFile, setCostFile] = useState();
    const [costFileId, setCostFileId] = useState();
    
    const [notes, setNotes] = useState('');

    const [branch, setBranch] = useState('');
    const [projectManagerString, setProjectManagerString] = useState('');
    const [projectManager, setProjectManager] = useState('');
    const [availableProjectManagers, setAvailableProjectManagers] = useState([]);
    const [availableProjectManagersError, setAvailableProjectManagersError] = useState('');
    const [salesLink, setSalesLink] = useState('');

    const [submitting, setSubmitting] = useState(false);

    const approvalProps = {
        currency: currency,
        setCurrency: setCurrency,
    };

    useEffect(() => {
        getFilteredProjectManagers(accessToken,branch,setAvailableProjectManagers,setAvailableProjectManagersError);
    },[branch]);

    useEffect(() => {
        if(contractAmount > 0 && costAmount > 0){
            let result = ((1-(costAmount/contractAmount))*100);
            setMargin(result.toFixed(2));
        }
        else setMargin(0);
    },[contractAmount,costAmount]);

    useEffect(() => {
        availableProjectManagers.forEach(element => {
            if(element.value === projectManager){
                setProjectManagerString(element.text);
            };
        });
    },[availableProjectManagers,projectManager]);

    useEffect(() => {
        if(requestRecord){
            setCustomer(requestRecord.customer);
            setCustomerLocation(requestRecord.customer.location);
            setProjectName(requestRecord.name);
            if(requestRecord.entityName) setEntityName(requestRecord.entityName);
            else setEntityName('* Same as Customer *');
            if(requestRecord.poNumber) setPoNumber(requestRecord.poNumber);
            else setPoNumber('* None Provided *');
            setProjectType(requestRecord.projectType);
            setStreet(requestRecord.address.street);
            setStreet2(requestRecord.address.street2);
            setCity(requestRecord.address.city);
            setState(requestRecord.address.state);
            setZip(requestRecord.address.zip);
            setBondRequired(requestRecord.bondRequired ? 'true' : 'false');
            setBusinessLicense(requestRecord.businessLicense ? 'true' : 'false');
            if(requestRecord.ccipInsturance) setOcipCcip(requestRecord.ccipInsturance);
            else setOcipCcip('None');
            setOwnerDirect(requestRecord.ownerDirect ? 'true' : 'false');
            setPermitsRequired(requestRecord.permitsRequired ? 'true' : 'false');
            setPrevailingWage(requestRecord.prevailingWage ? 'true' : 'false');
            setContractAmount(requestRecord.contractAmount);
            setCostAmount(requestRecord.costAmount);
            setMargin(requestRecord.margin);
            setRetainage(requestRecord.defaultRetainage);
            setTaxStatus(requestRecord.taxStatus);
            if(requestRecord.notes) setNotes(requestRecord.notes);
            else setNotes('* None *');
            setBranch(requestRecord.branch);
            setProjectManager(requestRecord.projectManager);
            setSalesLink(requestRecord.salesLink);
            if(requestRecord.budget){
                setBudgetType(requestRecord.budget.type);
                setEstimateNumber(requestRecord.budget.estimateNumber);
                setEstimateText(requestRecord.budget.text);
            }
        };
    },[requestRecord]);

    const createRecordObject = () => {
        const updatedRecord = requestRecord;

        updatedRecord.customer = customer;
        updatedRecord.location = customerLocation;
        updatedRecord.projectName = projectName.replace(/[^a-zA-Z0-9\s]/g, '');
        updatedRecord.projectType = projectType;
        if(entityName !== '* Same as Customer *') updatedRecord.entityName = entityName.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.entityName = null
        if(poNumber !== '* None Provided *') updatedRecord.customerPoNumber = poNumber.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.customerPoNumber = null;
        updatedRecord.address.street = street.replace(/[^a-zA-Z0-9\s]/g, '');
        if(street2) updatedRecord.address.street2 = street2.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.address.street2 = null;
        updatedRecord.address.city = city.replace(/[^a-zA-Z0-9\s]/g, '');
        updatedRecord.address.state = state.replace(/[^a-zA-Z0-9\s]/g, '');
        updatedRecord.address.zip = zip.replace(/[^a-zA-Z0-9\s]/g, '');
        updatedRecord.bondRequired = bondRequired === 'true' ? true : false;
        updatedRecord.businessLicenseRequired = businessLicense === 'true' ? true : false;
        updatedRecord.ownerDirectPurchase = ownerDirect === 'true' ? true : false;
        updatedRecord.prevailingWage = prevailingWage === 'true' ? true : false;
        updatedRecord.permitsRequired = permitsRequired === 'true' ? true : false;
        updatedRecord.ccipInsurance = ocipCcip;
        updatedRecord.totalContractAmount = contractAmount;
        updatedRecord.totalCostAmount = costAmount;
        updatedRecord.margin = margin;
        updatedRecord.defaultRetainage = retainage;
        updatedRecord.taxStatus = taxStatus;
        if(notes !== '* None *') updatedRecord.notes = notes.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.notes = null;
        updatedRecord.branch = branch;
        updatedRecord.projectManager = projectManager;
        updatedRecord.salesLink = salesLink;
        updatedRecord.budget.estimateNumber = estimateNumber;
        updatedRecord.serviceContractList = serviceContracts;

        if(taskFileId) updatedRecord.budget.taskFileName = taskFileId;
        if(revenueFileId) updatedRecord.budget.revenueFileName = revenueFileId;
        if(costFileId) updatedRecord.budget.costFileName = costFileId;

        return updatedRecord;
    };

    useEffect(() => {
        let ready = true;

        if(!submitting) ready = false;
        if(taskFile && !taskFileId) ready = false;
        if(revenueFile && !revenueFileId) ready = false;
        if(costFile && !costFileId) ready = false;

        if(ready) finalUpdate();

    },[taskFileId,revenueFileId,costFileId]);

    const finalUpdate = async () => {
        console.log('Final Update')
        const updatedRecord = createRecordObject();
        newProjectBookingDataService.updateRecord(accessToken,updatedRecord).then(res => {
            if(res.data.id){
                console.log('Successful Update')
                setEditRecord(false);
                recordObject.selectRecord(res.data.id);
            };  
        });
    };

    const updateRecord = async () => {
        setSubmitting(true);

        if(taskFile) await newProjectBookingDataService.updateProjectBookingTask(accessToken,taskFile[0],requestRecord.name).then(res => setTaskFileId(res.data.fileName));
        if(revenueFile) await newProjectBookingDataService.updateProjectBookingRevenue(accessToken,revenueFile[0],requestRecord.name).then(res => setRevenueFileId(res.data.fileName));
        if(costFile) await newProjectBookingDataService.updateProjectBookingCost(accessToken,costFile[0],requestRecord.name).then(res => setCostFileId(res.data.fileName));

        if(!taskFile && !revenueFile && !costFile ) finalUpdate();
    };

    const getContract = async (id) => {
        const file = await newProjectBookingDataService.getContract(accessToken,id);
        setSelectedPdf(file);
    };

    const getOcip = async (id) => {
        const file = await newProjectBookingDataService.getOcip(accessToken,id);
        setSelectedPdf(file);
    };

    const getPrevailing = async (id) => {
        const file = await newProjectBookingDataService.getPrevailing(accessToken,id);
        setSelectedPdf(file);
    };

    const getTaxExemption = async (id) => {
        const file = await newProjectBookingDataService.getTaxExemption(accessToken,id);
        setSelectedPdf(file);
    };

    const getBonding = async (id) => {
        const file = await newProjectBookingDataService.getBonding(accessToken,id);
        setSelectedPdf(file);
    };

    const closePdf = () => {
        setPdfViewer(false);
        setSelectedPdf(null);
    };

    const selectPdf = (selection, id) => {
        setPdfViewer(true);
        if(selection === 'Contract') getContract(id);
        if(selection === 'Ocip') getOcip(id);
        if(selection === 'Prevailing Wage') getPrevailing(id);
        if(selection === 'Tax Exemption') getTaxExemption(id);
        if(selection === 'Bonding') getBonding(id);
    };

    const denySelection = () => {
        setConfirmText('');
        setConfirmWindow(false);
    };

    const approve = () => {
        approveRecord(currency);
    };

    const approveSelection = () => {
        setConfirmText('');
        setConfirmWindow(false);
        callAfterFunction();
    };

    const closeRejection = () => {
        setRejecting(false);
    };

    const confirmReject = () => {
        rejectRecord(rejectionText,closeRejection);
    };

    const openRejection = () => {
        setRejecting(true);
    };

    const exitApproval = () => {
        setApprovalWindow(false);
    };

    const enterApproval = () => {
        setApprovalWindow(true);
    };

    const confirmSelection = (text,confirmFunction) => {
        setConfirmText(text);
        setConfirmWindow(true);
        setCallAfterFunction(() => confirmFunction);
    };

    const headerObject = {
        requestRecord:requestRecord,
        closeRecord:closeRecord,
        markInactive:markInactive,
        markActive:markActive,
        confirmSelection:confirmSelection,
        approveRecord:approveRecord,
        openRejection:openRejection,
        editRecord:editRecord,
        setEditRecord:setEditRecord,
        updateRecord:updateRecord,
        enterApproval:enterApproval,
        projectCreateComplete:projectCreateComplete,
        continueApproving:continueApproving,
        approvalFinished:approvalFinished,
    };

    const projectAddressObject = {
        street: street,
        setStreet: setStreet,
        street2: street2,
        setStreet2: setStreet2,
        city: city,
        setCity: setCity,
        state: state,
        setState: setState,
        zip: zip,
        setZip: setZip,
    };
    
    const formObject = {
        accessToken:accessToken,
        requestRecord:requestRecord,
        editRecord:editRecord,
        customer: customer,
        setCustomer: setCustomer,
        customerLocation: customerLocation,
        setCustomerLocation: setCustomerLocation,
        projectName: projectName,
        setProjectName: setProjectName,
        projectType:projectType,
        setProjectType:setProjectType,
        projectAddressObject:projectAddressObject,
        entityName: entityName,
        setEntityName: setEntityName,
        poNumber: poNumber,
        setPoNumber: setPoNumber,
        bondRequired: bondRequired,
        setBondRequired: setBondRequired,
        businessLicense: businessLicense,
        setBusinessLicense: setBusinessLicense,
        ocipCcip: ocipCcip,
        setOcipCcip: setOcipCcip,
        ownerDirect: ownerDirect,
        setOwnerDirect: setOwnerDirect,
        permitsRequired: permitsRequired,
        setPermitsRequired: setPermitsRequired,
        prevailingWage: prevailingWage,
        setPrevailingWage: setPrevailingWage,
        contractAmount: contractAmount,
        setContractAmount: setContractAmount,
        costAmount: costAmount,
        setCostAmount: setCostAmount,
        margin: margin,
        setMargin: setMargin,
        retainage:retainage,
        setRetainage:setRetainage,
        taxStatus: taxStatus,
        setTaxStatus: setTaxStatus,
        notes: notes,
        setNotes: setNotes,
        branch:branch,
        setBranch,setBranch,
        projectManagerString,projectManagerString,
        projectManager: projectManager,
        setProjectManager: setProjectManager,
        availableProjectManagers:availableProjectManagers,
        salesLink: salesLink,
        setSalesLink: setSalesLink,
        rejectedRecord:rejectedRecord,
        budgetType:budgetType,
        estimateNumber:estimateNumber,
        setEstimateNumber:setEstimateNumber,
        estimateText:estimateText,
        taskFile:taskFile,
        setTaskFile:setTaskFile,
        revenueFile:revenueFile,
        setRevenueFile:setRevenueFile,
        costFile:costFile,
        setCostFile:setCostFile,
        serviceContracts:serviceContracts,
        updateServiceContract:updateServiceContract,
    };

    return(
        <div className='Record-Window'>
            {   rejecting &&
                <RejectionWindow closeRejection={closeRejection} confirmReject={confirmReject} setRejectionText={setRejectionText}/>
            }
            {   !confirmWindow && !approvalWindow && !rejecting &&
            <>
                <RecordHeader headerObject={headerObject}/>
                {   requestRecord &&
                <>
                    <RecordForm formObject={formObject}/>
                    <RecordFiles requestRecord={requestRecord} selectPdf={selectPdf}/>
                </>
                }
                
            </>
            }
            {   approvalWindow &&
                <FinalApproveWindow title={confirmText} confirm={approve} deny={exitApproval} approvalProps={approvalProps}/>
            }
            {   confirmWindow && 
                <ConfirmationWindow title={confirmText} confirm={approveSelection} deny={denySelection}/>
            }
            {   pdfViewer &&
                <RecordPdfViewer closePdf={closePdf} selectedPdf={selectedPdf}/>
            }
        </div>
    );
};

const Loader = () => {
    return (
      <RotatingLines
        strokeColor="rgb(6, 89, 79)"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
    )
};

const ApprovalWindow = ({accessToken,approvalFinished,projectCreateComplete,projectAlreadyCreated,closeWindow,boxLink,acumaticaLink,budgetCreateComplete,
    setBudgetCreateComplete,budgetAlreadyCreated,budget,serviceContractsRequired, serviceContracts,setApprovalFinished,goBackToReview}) => {
    const [ closingProjectCreate, setClosingProjectCreate ] = useState(projectAlreadyCreated);
    const [ closingBudgetSubmit, setClosingBudgetSubmit] = useState(budgetAlreadyCreated);

    useEffect(() => {
        if(projectCreateComplete){
            setTimeout(() => {
                setClosingProjectCreate(true);
            },1000)};
    },[projectCreateComplete]);


    useEffect(() => {
        if(budgetCreateComplete){
            setTimeout(() => {
                setClosingBudgetSubmit(true);
            },1000)};
    },[budgetCreateComplete]);

    const openProjectLink = () => {
        const newWindow = window.open(acumaticaLink, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const openStandardBudgetInstructions = () => {
        const newWindow = window.open(standardBudgetUploadInstructions, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const openProEstBudgetInstructions = () => {
        const newWindow = window.open(proestBudgetUploadInstructions, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const BudgetInputWindow = ()  => {
        if(budget.type === 'Standard') return(
            <>
                <div className='Record-Budget-Submit-Title'>
                    Standard Budget Upload
                </div>

                <div className='Record-Budget-Submit-Instructions'>
                    Download the task, revenue, and cost files below and upload them into the project.
                </div>

                <div className='Record-Budget-Submit-Instructions' onClick={() => openStandardBudgetInstructions()}>
                    Click here for detailed instructions on how to upload the budget.
                </div>

                <button className='Record-Budget-Submit-Link' onClick={() => openProjectLink()}>Acumatica Project Link</button>

                <div className='Record-Budget-Files'>
                    <button className='Record-Sales-Link' onClick={() => openProjectBookingTask(accessToken,budget.taskFileName)}>Download Tasks</button>
                    <button className='Record-Sales-Link' onClick={() => openProjectBookingRevenue(accessToken,budget.revenueFileName)}>Download Revenue Budget</button>
                    <button className='Record-Sales-Link' onClick={() => openProjectBookingCost(accessToken,budget.costFileName)}>Download Cost Budget</button>
                </div>

                <button className='Record-Budget-Submit-Confirm' onClick={() => setBudgetCreateComplete(true)}>Budget Upload Complete</button>
            </>
        )
        else if(budget.type === 'ProEst') return(
            <>
                <div className='Record-Budget-Submit-Title'>
                    ProEst Budget Upload
                </div>

                <div className='Record-Budget-Submit-Instructions'>
                    Open the ProEst budget and navigate to the estimate center. Edit the estimate, then select the Acumatica project and mark status to "Awarded-Sync" and save.
                </div>

                <div className='Record-Budget-Submit-Instructions Budget-Selectable' onClick={() => openProEstBudgetInstructions()}>
                    Click here for detailed instructions on how to upload the budget.
                </div>
                
                <div className='Record-Budget-Submit-Row'>
                    <button className='Record-Budget-Submit-Link Budget-Selectable' onClick={() => openProjectLink()}>Acumatica Project Link</button>
                </div>

                <div className='Record-Budget-Submit-Row'>
                    <button className='Record-Budget-Submit-Link Budget-Selectable' onClick={() => openProestBudgetLink(budget.estimateNumber)}>ProEst Estimate Link</button>
                </div>

                <div className='Record-Budget-Submit-Row'>
                    <button className='Record-Budget-Submit-Confirm Budget-Selectable' onClick={() => setBudgetCreateComplete(true)}>Budget Upload Complete</button>
                </div>

            </>
        )
        else return(
            <>

                <div className='Record-Budget-Submit-Title'>
                    No Budget Provided
                </div>

                <div className='Record-Budget-Submit-Instructions'>
                    No budget has been uploaded. Please send an email to the submitter and ensure they are aware this will be required.
                </div>

                <button className='Record-Budget-Submit-Link' onClick={() => openProjectLink()}>Acumatica Project Link</button>

                <button className='Record-Budget-Submit-Confirm' onClick={() => setBudgetCreateComplete(true)}>Confirmation Email Sent</button>

            </>
        );
    };

    const ServiceContractsWindow = () => {
        return(
            <div className='Record-Service-Submit-Confirm' >


                <button className='Record-Budget-Submit-Confirm' onClick={() => setApprovalFinished(true)}>Finalize Project Booking</button>

            </div>
        )
    };

    return(
        <div className='Approval'>
            {   !projectCreateComplete && 
            <>
                <Loader />
                <div className='Approval-Text'>
                    Please Wait... Booking Project.
                </div>
            </>
            }

            {   projectCreateComplete && !closingProjectCreate &&
            <>
                <FaCheck className='Approval-Symbol' />
                <div className='Approval-Text'>
                    Project Booked... Please Setup Budget
                </div>
            </>
            }

            {   projectCreateComplete && closingProjectCreate && !budgetCreateComplete &&
                <div className='Record-Budget-Submit-Window'>
                    <div className='Record-Budget-Submit-Header'>
                        <FaBackward className='Record-Budget-Submit-Header-Icon Budget-Selectable' onClick={() => goBackToReview()}/>
                    </div>
                    <BudgetInputWindow />
                </div> 
            }


            {   projectCreateComplete && closingProjectCreate && budgetCreateComplete && !closingBudgetSubmit &&
            <>
                <Loader />
                <div className='Approval-Text'>
                    Please Wait... Finalizing Project Budget
                </div>
            </>
            }

            {   projectCreateComplete && closingProjectCreate && budgetCreateComplete && closingBudgetSubmit && serviceContractsRequired && !serviceContracts &&
            <>
                <Loader />
                <div className='Approval-Text'>
                    Please Wait... Creating Service Contracts
                </div>
            </>
            }

            {   projectCreateComplete && closingProjectCreate && budgetCreateComplete && closingBudgetSubmit && serviceContractsRequired && serviceContracts &&
                <ServiceContractsWindow />
            }


            {   approvalFinished &&
            <>
                <FaCheck className='Approval-Symbol' />
                <div className='Approval-Text'>
                    Project Booking Complete... Marking Request Inactive
                </div>
            </>
            }

        </div>
    );
};

const ProjectBookingReview = () => {
    const contextObject = useContext(Context);

    const [recordId, setRecordId] = useState();

    const [orderBy, setOrderBy ] = useState('date');

    const [sortDirection, setSortDirection ] = useState('ASC');
    const [activeSort, setActiveSort ] = useState(true);

    const [rejectedRecord, setRejectedRecord] = useState();
    const [requestList, setRequestList ] = useState();
    const [requestRecord, setRequestRecord] = useState();
    const [searchInput, setSearchInput ] = useState('');
    const [searchList, setSearchList] = useState([]);

    const [serviceContracts, setServiceContracts] = useState([]);
    const [serviceContractsError, setServiceContractsError] = useState('');

    const [approving, setApproving ] = useState(false);
    const [projectCreateComplete, setProjectCreateComplete] = useState(false);
    const [budgetCreateComplete, setBudgetCreateComplete] = useState(false);
    const [approvalFinished, setApprovalFinished ] = useState(false);
    const [serviceContractsRequired, setServiceContractsRequired] = useState(false);

    const [boxLink, setBoxLink] = useState('');
    const [acumaticaLink, setAcumaticaLink] = useState('');

    const updateServiceContract = (contract, id, operation) => {
        let tempList = serviceContracts;
        if(operation === 'Edit'){
            tempList[id] = contract;
        }
        if(operation === 'Delete'){
            tempList = tempList.slice(id,1)
        }
        
        setServiceContracts(tempList);
    };

    const goBackToReview = () => {
        setApproving(false);
    };

    const continueApproving = () => {
        setApproving(true);
    };

    const createServiceContracts = async () => {
        const serviceContractsResponse = await newProjectBookingDataService.postServiceContracts(contextObject.apiToken,serviceContracts);
        if(serviceContractsResponse.status > 199 && serviceContractsResponse.status < 300){
            newProjectBookingDataService.markRequestComplete(contextObject.apiToken, requestRecord.id);
            setApprovalFinished(true);
            markInactive();
        }
        else{
            alert('SERVER FAILURE - Please Contact IT for assistance');
        };
    };

    useEffect(() => {
        if(requestRecord && budgetCreateComplete){
            if(requestRecord.projectType === 'Project'){
                newProjectBookingDataService.markRequestComplete(contextObject.apiToken, requestRecord.id);
                setApprovalFinished(true);
                markInactive();
            }
            else createServiceContracts();
        };
    },[budgetCreateComplete]);

    useEffect(() => {
        if(requestRecord){
            if(requestRecord.projectType) {
                if(requestRecord.projectType === 'Service') setServiceContractsRequired(true);
                else setServiceContractsRequired(false);
            };

            if(requestRecord.acumaticaProjectId) {
                setApprovalFinished(false);
                setProjectCreateComplete(true);
                setAcumaticaLink(`https://cornerstoneinc.acumatica.com/(W(7))/Main?CompanyID=Pilot&ScreenId=PM301000&BaseType=P&ContractCD=${requestRecord.acumaticaProjectId}`);
            };

            if(requestRecord.boxFolderId){
                setBoxLink(`https://cornerstonedetentionproducts.app.box.com/folder/${requestRecord.boxFolderId}`);
            };

            if(requestRecord.completeDate){
                setApprovalFinished(true);
            };

            newProjectBookingDataService.getRejectedRecord(contextObject.apiToken, requestRecord.id).then(res => setRejectedRecord(res.data.rejection));
            getServiceContracts(contextObject.apiToken, requestRecord.id,setServiceContracts,setServiceContractsError);
        }
    },[requestRecord]);

    const approveRecord = async (currency) => {
        setApprovalFinished(false);
        setApproving(true);
        
        const approvalObject = {
            id: recordId,
            currency: currency,
        };
        try{
            const approval = await newProjectBookingDataService.approveRecord(contextObject.apiToken, approvalObject);

            const boxFolderId = approval.data.box;
            const acumaticaId = approval.data.acumatica;
            if(approval.status > 199 && approval.status < 300){
                selectRecord(recordId);
                setProjectCreateComplete(true);
                setBoxLink(`https://cornerstonedetentionproducts.app.box.com/folder/${boxFolderId}`);
                setAcumaticaLink(`https://cornerstoneinc.acumatica.com/(W(7))/Main?CompanyID=Pilot&ScreenId=PM301000&BaseType=P&ContractCD=${acumaticaId}`);
            }
            else{
                alert('SERVER FAILURE - Please Contact IT for assistance');
            };
        }
        catch(error){
            alert('SERVER FAILURE - Please Contact IT for assistance');
            closeRecord();
        }
    };

    const closeWindow = () => {
        setRecordId(null);
        setApproving(false);
        setApprovalFinished(false);
        setProjectCreateComplete(false);
        setBudgetCreateComplete(false);
        setApprovalFinished(false);
        setServiceContractsRequired(false);

        refreshList();
        setSearchInput('');
    };

    const rejectRecord = ( rejectionText, closeRejection) => {
        const rejection = {
            requestId: requestRecord.id,
            text: rejectionText,
        };

        newProjectBookingDataService.rejectRecord(contextObject.apiToken, rejection).then(res => {closeRejection(); selectRecord(recordId);});
    };

    const markInactive = () => {
        newProjectBookingDataService.inactivateRecord(contextObject.apiToken, recordId).then(res => {closeRecord(); refreshList()});
    };

    const markActive = () => {
        newProjectBookingDataService.activateRecord(contextObject.apiToken, recordId).then(res => {closeRecord(); refreshList()});
    };

    const closeRecord = () => {
        setRecordId(null);
        setApproving(false);
        setApprovalFinished(false);
        setProjectCreateComplete(false);
        setBudgetCreateComplete(false);
        setApprovalFinished(false);
        setServiceContractsRequired(false);
    };

    const resetList = () => {
        setSearchInput('');
        setOrderBy('date');
        setActiveSort(true);
        refreshList();
        setSortDirection('ASC');
    };

    const selectSearchItem = (projectName) => {
        const filterObject = {
            searchInput:projectName,
        };
        newProjectBookingDataService.getList(contextObject.apiToken, filterObject).then(res => setRequestList(res.data.projectBookingList));
    };

    const refreshSearchList = async () => {
        if(searchInput){
            const searchObject = {
                searchInput: searchInput,
            };
            newProjectBookingDataService.getSearchList(contextObject.apiToken, searchObject).then(res => setSearchList(res.data.searchList));
        }
    };

    useEffect(() => {
        refreshSearchList();
    },[searchInput]);

    const selectRecord = (id) => {
        setRecordId(id);
        newProjectBookingDataService.getRecord(contextObject.apiToken, id).then(res => setRequestRecord(res.data.record));
    };

    const flipSortOrder = () => {
        if(sortDirection === 'ASC') setSortDirection('DESC');
        else setSortDirection('ASC');
    };

    const handleOrderClick = (newOrderBy) => {
        if(newOrderBy === orderBy) flipSortOrder();
        else setOrderBy(newOrderBy)
    };

    const toggleFlag = (id) => {
        newProjectBookingDataService.toggleFlag(contextObject.apiToken, id).then(res => refreshList());
    };

    const refreshList = () => {
        const filterObject = {
            active: activeSort,
            sortDirection: sortDirection,
            orderBy:orderBy,
            searchInput:searchInput,
        };
        newProjectBookingDataService.getList(contextObject.apiToken, filterObject).then(res => setRequestList(res.data.list));
    };

    useEffect(() => {
        refreshList();
    },[sortDirection, orderBy, activeSort]);

    const headerObject = {
        setSearchInput:setSearchInput,
        sortDirection:sortDirection,
        flipSortOrder:flipSortOrder,
        screenWidth:contextObject.screenWidth,
        handleOrderClick:handleOrderClick,
        orderBy: orderBy,
        searchList:searchList,
        selectSearchItem:selectSearchItem,
        resetList:resetList,
        activeSort:activeSort,
        setActiveSort:setActiveSort,
    };

    const listObject = {
        requestList:requestList,
        selectRecord:selectRecord,
        toggleFlag:toggleFlag,
        screenWidth: contextObject.screenWidth,
    };

    const recordObject = {
        requestRecord:requestRecord,
        closeRecord:closeRecord,
        markInactive:markInactive,
        markActive:markActive,
        approveRecord:approveRecord,
        contextObject: contextObject,
        rejectRecord:rejectRecord,
        selectRecord:selectRecord,
        rejectedRecord:rejectedRecord,
        serviceContracts:serviceContracts,
        updateServiceContract:updateServiceContract,
        projectCreateComplete:projectCreateComplete,
        continueApproving:continueApproving,
        approvalFinished:approvalFinished,
    };

    return(
        <div className='Project-Booking-Review'>
            {   !recordId && !approving &&
            <>
                <RequestHeader headerObject={headerObject}/>
                <RequestDisplay listObject={listObject}/>      
            </>
            }
            {   recordId && !approving &&
                <RecordWindow accessToken={contextObject.apiToken} recordObject={recordObject}/>
            }
            {   approving &&
                <ApprovalWindow accessToken={contextObject.apiToken} approvalFinished={approvalFinished} projectCreateComplete={projectCreateComplete} projectAlreadyCreated={`${requestRecord.acumaticaProjectId ? true : false}`} closeWindow={closeWindow} boxLink={boxLink} acumaticaLink={acumaticaLink} budgetCreateComplete={budgetCreateComplete} setBudgetCreateComplete={setBudgetCreateComplete} budgetAlreadyCreated={false} budget={requestRecord.budget} serviceContractsRequired={serviceContractsRequired} setApprovalFinished={setApprovalFinished} goBackToReview={goBackToReview}/>
            }
        </div>
    )
};

export default ProjectBookingReview;